import {MediumText} from "components/ui/text";
import {COLORS, PADDING, SPACING} from "constants/theme";
import React from "react";
import {StyleSheet, View} from "react-native";
import {AssetCard} from "../AssetCard";
import {CollectibleCard} from "../CollectibleCard";
import {Collectible, TokenBag} from "../../../../_state/state-types";

interface IConnectedProps {
  to: string
  action: string
  tokenBag?: TokenBag;
  collectible?: Collectible;
}

export const Connected: React.FC<IConnectedProps> = ({to, tokenBag, collectible, action}) => {
  return (
      <View style={styles.wrapper}>
        <MediumText style={{color: '#030E20', marginBottom: SPACING.SM}}>To {to}</MediumText>

        {tokenBag &&
           <AssetCard abbrev={tokenBag.symbol} action={action} value={tokenBag.amountUi}
                      balance={tokenBag.amountUiString}/>
        }
        {collectible &&
           <CollectibleCard action={action} name={collectible.name} price='~$70' value='7.7 sol'
                            style={{marginTop: 16}}/>
        }
      </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
    paddingTop: PADDING.XL
  }
})

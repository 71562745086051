import {BannerText, ThinText} from 'components/ui/text';
import React from 'react';
import {Image, StyleSheet, TouchableOpacity, View} from 'react-native';
import {EStatus} from '../../_state/state-types';

interface IWalletCardProps {
  iconUrl: string
  name: string
  walletAddress: string
  onPress?: () => void
  status?: EStatus
}

export const WalletCard: React.FC<IWalletCardProps> = ({iconUrl, name, walletAddress, status, onPress}) => {
  return (
      <TouchableOpacity onPress={onPress} style={styles.wrapper}>
        <View style={styles.wrapperMain}>
          <View style={styles.iconWrapper}>
            <Image style={styles.icon} source={{uri: iconUrl}}/>
          </View>

          <View style={styles.main}>
            <ThinText style={{fontWeight: '600'}}>{name}</ThinText>
            <BannerText
                style={{color: '#9C9C9C', textTransform: 'lowercase', fontSize: 8}}
            >
              {walletAddress}
            </BannerText>
          </View>
        </View>

        <BannerText
            style={{fontSize: 8, color: '#272727'}}>{status == EStatus.ADDED ? 'PENDING' : 'LINKED'}</BannerText>
      </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#B0B0B0',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16
  },
  wrapperMain: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16
  },
  iconWrapper: {
    width: 28,
    height: 28,
    borderRadius: 50,
    backgroundColor: '#9C9C9C'
  },
  icon: {
    width: '100%',
    height: '100%',
    borderRadius: 50
  },
  main: {
    display: 'flex',
    gap: 4
  }
})

import { BackIcon } from 'assets/icons/BackIcon';
import { PlusIcon } from 'assets/icons/PlusIcon';
import { Button } from 'components/Button';
import { COLORS } from 'constants/theme';
import React, { PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { NormalText } from '../text';

interface Props extends PropsWithChildren {
    onPress: () => void
    label?: string
    icon?: boolean
    backIcon?: boolean
    style?: any
}

export const WhiteButton: React.FC<Props> = ({ label, onPress, icon, backIcon, style={} }) => {
    return (
        <Button 
            label=''
            onPress={onPress}
            styleContainer={{...styles.whiteButton, ...style}}
        >
            {icon && 
                <PlusIcon width={16} height={16} />
            }
            <NormalText>{label}</NormalText>
            {backIcon && 
                <BackIcon width={10} height={10} style={{transform: 'rotate(180deg)'}}/>
            }
        </Button>
    )
}

export const BlackButton: React.FC<Props> = ({ label, onPress, icon, style={} }) => {
    return (
        <Button 
            label={label} 
            onPress={onPress}
            styleContainer={{...styles.blackButton, ...style}}
            styleLabel={{color: COLORS.WHITE}}
        >
            {icon && 
                <BackIcon 
                    width={10} 
                    height={10} 
                    color={COLORS.WHITE}
                    style={{transform: 'rotate(180deg)'}}
                />
            }
        </Button>
    )
}

export const BackNavigationButton: React.FC<Props> = ({ onPress }) => {
    return (
        <Button
            label=''
            onPress={onPress}
            styleContainer={styles.backButton}
        >
            <BackIcon/>
        </Button>
    )
}

export const RightNavigationButton: React.FC<Props> = ({ onPress, children }) => {
    return (
        <Button
            label=''
            onPress={onPress}
            styleContainer={styles.rightButton}
        >
            {children}
        </Button>
    )
}

const styles = StyleSheet.create({
    whiteButton: {
        width: '100%',
        maxWidth: 375
    },
    blackButton: {
        backgroundColor: COLORS.BLACK,
        width: '100%',
        maxWidth: 375
    },
    backButton: {
        alignItems: 'flex-start',
        minWidth: 'auto',
        padding: 0,
        marginLeft: 10,
        marginTop: 8,
        width: 24,
        height: 24,
        borderWidth: 0,
    },
    rightButton: {
        padding: 0, 
        minWidth: 'auto', 
        borderWidth: 0, 
        marginRight: 16
    }
})
import { CommonActions, useNavigation } from "@react-navigation/native";
import { WalletConnectedModal } from "components/Modals";
import { NftCard } from "components/NftCard";
import { BlackButton } from "components/ui/button";
import { ScreenContainer } from "components/ui/container";
import { HeaderText, MediumText } from "components/ui/text";
import { ERoute } from "constants/routes";
import { BRADIUS, PADDING, SPACING } from "constants/theme";
import React, { useCallback, useState } from "react";
import { View, StyleSheet } from "react-native";
import { useRecoilValue } from "recoil";
import { homepageAtom } from "../../../_state/screen-props";

interface IConfirmPaymentProps {}

export const ConfirmPayment: React.FC<IConfirmPaymentProps> = () => {
    const homepageProps = useRecoilValue(homepageAtom)
    const [openConnectedModal, setOpenConnectedModal] = useState(false)

    const navigation = useNavigation()

    const handleNavigateToRoute = useCallback(() => {
        navigation.dispatch({...CommonActions.navigate(ERoute.HOMEPAGE)})
        setOpenConnectedModal(false)
    }, [navigation, setOpenConnectedModal])

    const handleOpenModal = useCallback(() => {
        setOpenConnectedModal(true)
    }, [setOpenConnectedModal])
    
    return (
        <ScreenContainer style={{alignItems: 'center', justifyContent: 'space-between'}}>
            <View style={{width: '100%', alignItems: 'center'}}>
                <View style={{gap: SPACING.LG, alignItems: 'center', marginTop: 16}}>
                    <MediumText style={{color: '#6D6D6D'}}>You are sending</MediumText>

                    {homepageProps.asset
                        ? <View style={{gap: SPACING.LG, alignItems: 'center'}}>
                            <HeaderText style={{fontWeight: '600'}}>300Eth</HeaderText>
                            <MediumText style={{color: '#6D6D6D'}}>$2,0000.00 Usd</MediumText>
                        </View>
                        : <NftCard 
                            imageUrl='' 
                            name='Pixelated #8790' 
                            value='7.7 sol' 
                            price='~$70'
                            style={{width: 160, borderRadius: BRADIUS.MD}}
                        />
                    }
                </View>

                <View style={styles.info}>
                    <View style={styles.infoRow}>
                        <MediumText style={{color: '#6D6D6D'}}>From</MediumText>
                        <MediumText style={{color: '#6D6D6D'}}>@stacheid</MediumText>
                    </View>

                    <View style={styles.infoRow}>
                        <MediumText style={{color: '#6D6D6D'}}>Recipient Address/ID</MediumText>
                        <MediumText style={{color: '#6D6D6D'}}>@stacheid</MediumText>
                    </View>

                    <View style={styles.infoRow}>
                        <MediumText style={{color: '#6D6D6D'}}>Amount</MediumText>
                        <MediumText style={{color: '#6D6D6D'}}>100 Eth</MediumText>
                    </View>

                    <View style={styles.infoRow}>
                        <MediumText style={{color: '#6D6D6D'}}>Transaction Fee</MediumText>
                        <MediumText style={{color: '#6D6D6D'}}>0.005 Eth</MediumText>
                    </View>

                    <View style={styles.infoRow}>
                        <MediumText style={{color: '#6D6D6D'}}>Total Amount</MediumText>
                        <MediumText style={{color: '#6D6D6D'}}>600.005 Eth</MediumText>
                    </View>
                </View>
            </View>

            <View style={{width: '100%', alignItems: 'center'}}>
                <BlackButton 
                    label='Complete payment' 
                    onPress={handleOpenModal} 
                    style={{marginBottom: 32}} 
                    icon 
                />
            </View>

            <WalletConnectedModal
                text='Transaction Complete'
                isOpen={openConnectedModal}
                handleNavigate={handleNavigateToRoute}
            />
        </ScreenContainer>
    )
}

const styles = StyleSheet.create({
    info: {
        gap: SPACING.XL,
        width: '100%',
        marginTop: 40
    },
    infoRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: BRADIUS.MD,
        padding: PADDING.LG,
        backgroundColor: 'rgba(0, 0, 0, 0.03);'
    },
})
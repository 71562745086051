import { CommonActions, useNavigation } from "@react-navigation/native";
import { Avatar } from "components/Avatar";
import { BlackButton } from "components/ui/button";
import { ScreenContainer } from "components/ui/container";
import { HeaderText, NormalText, SubHeaderText } from "components/ui/text";
import { WalletCard } from "components/WalletCard";
import { WalletInfo } from "components/WalletInfo";
import { ERoute } from "constants/routes";
import { COLORS, SPACING } from "constants/theme";
import React, { useCallback } from "react";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";

interface IUserProps {}

export const SelectUser: React.FC<IUserProps> = () => {
    const navigation = useNavigation()
    
    const handleNavigateToScreen = useCallback(() => {
        navigation.dispatch({...CommonActions.navigate(ERoute.EDIT_USER)})
    }, [navigation])

    return (
        <ScreenContainer style={{alignItems: 'center', justifyContent: 'space-between'}}>
            <View style={{width: '100%', alignItems: 'center'}}>
                <Avatar style={styles.avatar} iconUrl="" />

                <SubHeaderText style={{marginTop: 12}}>@userstacheid</SubHeaderText>

                <View style={{width: '100%', alignItems: 'flex-start'}}>
                    <HeaderText 
                        style={{
                            fontSize: 24, 
                            lineHeight: 36,
                            marginTop: 48,
                            fontWeight: '600'
                        }}
                    >
                        Wallets
                    </HeaderText>
                </View>

                <View style={{gap: SPACING.XL, width: '100%', marginTop: 16}}>
                    <WalletCard iconUrl="" name='Metamask' walletAddress="wallet address" onPress={handleNavigateToScreen} />
                    <WalletCard iconUrl="" name='Metamask' walletAddress="wallet address" onPress={handleNavigateToScreen} />
                    <WalletCard iconUrl="" name='Metamask' walletAddress="wallet address" onPress={handleNavigateToScreen} />
                </View>
            </View>

            <View style={{gap: 30, width: '100%', alignItems: 'center'}}>
                <BlackButton 
                    label='Send' 
                    onPress={() => console.log('Send')} 
                    icon 
                />

                <TouchableOpacity 
                    onPress={() => console.log('Delete user function')}
                    style={{
                        borderBottomWidth: 1, 
                        borderBottomColor: COLORS.BLACK, 
                        width: 'fit-content',
                        marginBottom: 56
                    }}
                >
                    <NormalText 
                        style={{
                            marginTop: 12, 
                            color: COLORS.BLACK
                        }}
                    >
                        Delete user
                    </NormalText>
                </TouchableOpacity>
            </View>
        </ScreenContainer>
    )
}

export const EditUser: React.FC<IUserProps> = () => {
    return (
        <ScreenContainer style={{alignItems: 'center', justifyContent: 'space-between'}}>
            <WalletInfo />

            <View style={{gap: 30, width: '100%', alignItems: 'center'}}>
                <BlackButton 
                    label='Send' 
                    onPress={() => console.log('Send')} 
                    icon 
                />

                <TouchableOpacity 
                    onPress={() => console.log('Delete user function')}
                    style={{
                        borderBottomWidth: 1, 
                        borderBottomColor: COLORS.BLACK, 
                        width: 'fit-content',
                        marginBottom: 56
                    }}
                >
                    <NormalText 
                        style={{
                            marginTop: 12, 
                            color: COLORS.BLACK
                        }}
                    >
                        Delete user
                    </NormalText>
                </TouchableOpacity>
            </View>
        </ScreenContainer>
    )
}

const styles = StyleSheet.create({
    avatar: {
        width: 96,
        height: 96,
    }
})
import {CommonActions, useNavigation} from '@react-navigation/native';
import {Avatar} from 'components/Avatar';
import {MediumText} from 'components/ui/text';
import {ERoute} from 'constants/routes';
import {COLORS} from 'constants/theme';
import {uniqueId} from 'lodash';
import {formatAddress} from 'programs/string-formatting';
import React, {useCallback} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {IWallet, TokenBag} from "../../../_state/state-types";
import {useAppActions} from "../../../_actions/app-actions";

interface IAssetsProps {
  assets: TokenBag[]
  wallet: IWallet;
  style?: any
}

export const Assets: React.FC<IAssetsProps> = ({wallet, assets, style = {}}) => {
  const navigation = useNavigation()
  const appActions = useAppActions();

  const handleNavigateToScreen = useCallback((tokenBag: TokenBag) => {
    appActions.setInteraction(wallet, tokenBag, null);
    navigation.dispatch({...CommonActions.navigate(ERoute.ASSETS_DETAILS)});
  }, [navigation])

  return (
      <View style={{...styles.wrapper, ...style}}>
        {assets?.map(token => {
          return (
              <TouchableOpacity key={uniqueId()} style={styles.item} onPress={() => handleNavigateToScreen(token)}>
                <View style={styles.tokenWrapper}>
                  <Avatar iconUrl=''/>

                  <View style={styles.token}>
                    <MediumText>{token.symbol}</MediumText>
                    <MediumText style={{color: '#737373'}}>{formatAddress(token.name)}</MediumText>
                  </View>
                </View>

                <View style={[styles.token, styles.tokenLeft]}>
                  <MediumText>{token.amountUiString} {token.symbol}</MediumText>
                  <MediumText> </MediumText>
                </View>
              </TouchableOpacity>
          )
        })}
      </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    gap: 16,
    backgroundColor: COLORS.WHITE,
    paddingTop: 16
  },
  item: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 16,
    paddingLeft: 16
  },
  token: {
    gap: 8
  },
  tokenWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12
  },
  tokenLeft: {
    alignItems: 'flex-end'
  }
})

import {AnchorWallet, useAnchorWallet, useWallet} from '@solana/wallet-adapter-react';
import {WalletMultiButton} from '@solana/wallet-adapter-react-ui';
import React, {useCallback, useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {useWalletActions} from '../../_actions/wallet-actions';
import {consoleLog} from '../../_helpers/debug';
import {keychainAtom} from '../../_state/keychain-state';
import {walletAtom} from '../../_state/wallet-state';
import {WalletConnectedModal, WalletNotLinkedModal} from 'components/Modals';
import {ERoute} from 'constants/routes';
import {CommonActions, useNavigation} from '@react-navigation/native';
import useAsyncEffect from "use-async-effect";
import {useStacheActions} from "../../_actions/stache-actions";
import {useAuthActions} from "../../_actions/auth-actions";
import {ScreenContainer} from 'components/ui/container';
import {BigHeaderText, NormalText} from 'components/ui/text';
import {COLORS} from 'constants/theme';

interface IConnectWalletProps {
}

export const ConnectWallet: React.FC<IConnectWalletProps> = () => {
  const {signMessage} = useWallet();
  const wallet = useRecoilValue(walletAtom);
  const keychain = useRecoilValue(keychainAtom);
  const stacheActions = useStacheActions();
  const walletActions = useWalletActions();
  const authActions = useAuthActions();
  const anchorWallet: AnchorWallet | undefined = useAnchorWallet();
  const navigation = useNavigation();

  const [openConnectedModal, setOpenConnectedModal] = useState(false)
  const [openNotLinkedModal, setOpenNotLinkedModal] = useState(false)

  // first step: populate the wallet state when the wallet is connected, including the solana client and programs
  useAsyncEffect(async () => {
    if (anchorWallet && !wallet) {
      consoleLog("Connecting wallet...");
      await walletActions.connectWallet(anchorWallet, signMessage)
    }
  }, [anchorWallet, wallet])

  // 2nd step: if wallet is connected and we've got the wallet state, but haven't checked for a keychain/stache, check here
  useAsyncEffect(async () => {
    if (wallet && !wallet.checkedForStache) {
      consoleLog("got wallet, keychain not checked", keychain);
      await stacheActions.checkForStache(wallet.address);
    } else if (!anchorWallet && wallet) {
      // otherwise, disconnect & logout
      console.log("no anchor no wallet");
      await authActions.logout();
    }
  }, [wallet, keychain, anchorWallet])

  // 3rd step: once we've checked for an exsiting stache, then we've also got the keychain and checked whether the connected wallet is linked
  useEffect(() => {
    if (wallet && wallet.checkedForStache) {
      console.log("keychain checked", keychain)
      if (wallet.walletLinked) {
        walletActions.setLinkedWalletData(wallet.address)
        walletActions.setBalancesData(wallet.address)
        consoleLog('wallet verified');
        setOpenConnectedModal(true);
      } else {
        setOpenNotLinkedModal(true);
      }
    }
  }, [wallet, setOpenConnectedModal, setOpenNotLinkedModal])

  const handleNavigateToRoute = useCallback((route: ERoute) => {
    return () => {
      navigation.dispatch({...CommonActions.navigate(route)})
      setOpenConnectedModal(false)
      setOpenNotLinkedModal(false)
    }
  }, [navigation, setOpenConnectedModal, setOpenNotLinkedModal])

  return (
      <ScreenContainer style={{justifyContent: 'center', alignItems: 'center'}}>
        <BigHeaderText>️Stache</BigHeaderText>
        <BigHeaderText>🌰 🐿</BigHeaderText>
        <br/>
        <WalletMultiButton>
          <NormalText style={{color: COLORS.WHITE}}>CONNECT</NormalText>
        </WalletMultiButton>

        <WalletConnectedModal
            text='Wallet connected to stache...'
            isOpen={openConnectedModal}
            handleNavigate={handleNavigateToRoute(ERoute.HOMEPAGE)}
            button
        />
        <WalletNotLinkedModal
            isOpen={openNotLinkedModal}
            close={() => setOpenNotLinkedModal(false)}
            handleNavigate={handleNavigateToRoute(ERoute.LINK_WALLET)}
            handleRegister={handleNavigateToRoute(ERoute.CREATE_NEW_STACHE)}
        />
      </ScreenContainer>
  )
}

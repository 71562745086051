import {CommonActions, useNavigation} from '@react-navigation/native';
import {NftCard} from 'components/NftCard';
import {ERoute} from 'constants/routes';
import {COLORS} from 'constants/theme';
import {uniqueId} from 'lodash';
import React, {useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import {Collectible, IWallet} from '../../../_state/state-types';
import {useAppActions} from "../../../_actions/app-actions";

interface ICollectiblesProps {
  wallet: IWallet;
  collectibles: Collectible[];
}

export const Collectibles: React.FC<ICollectiblesProps> = ({collectibles, wallet}) => {
  const navigation = useNavigation();
  const appActions = useAppActions();

  const handleNavigateToScreen = useCallback((collectible) => {
    appActions.setInteraction(wallet, null, collectible);
    navigation.dispatch({...CommonActions.navigate(ERoute.ASSETS_DETAILS)});
  }, [navigation])

  return (
      <View style={styles.wrapper}>
        {collectibles?.map(nft => {
          return (
              <NftCard
                  key={uniqueId()}
                  imageUrl={nft.imageUrl}
                  name={nft.name}
                  price='~$70'
                  value='7.7 sol'
                  onPress={() => handleNavigateToScreen(nft)}
              />
          )
        })}
      </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: 16,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    gap: 16,
    justifyContent: 'center',
    paddingBottom: 70,
    backgroundColor: COLORS.WHITE
  }
})

import {CommonActions, useNavigation} from "@react-navigation/native";
import {WhiteButton} from "components/ui/button";
import {BalanceContainer, ScreenContainer} from "components/ui/container";
import {BigHeaderText, MediumText, NormalText} from "components/ui/text";
import {ERoute} from "constants/routes";
import {BRADIUS} from "constants/theme";
import React, {useCallback} from "react";
import {ScrollView, StyleSheet, View} from "react-native";
import {consoleLog} from "../../_helpers/debug";
import {EStatus} from "../../_state/state-types";
import {useRecoilValue} from "recoil";
import {keychainAtom} from "../../_state/keychain-state";
import {connectedWalletAtom} from "../../_state/connectedwallet-state";
import {NoData} from "../../components/NoData";
import {WalletCard} from "components/WalletCard";

interface IManageWalletsProps {
  isWalletLinked: boolean
}

export const ManageWallets: React.FC<IManageWalletsProps> = ({isWalletLinked = true}) => {
  const navigation = useNavigation()
  const keychain = useRecoilValue(keychainAtom);
  const connectedWallet = useRecoilValue(connectedWalletAtom);

  const handleNavigateToScreen = useCallback(() => {
    navigation.dispatch({...CommonActions.navigate(ERoute.DELETE_WALLET)})
  }, [navigation])

  consoleLog("connectedWallet: ", connectedWallet);
  return (
      <ScreenContainer>
        <ScrollView>
          {isWalletLinked
              ? <>
                <BalanceContainer>
                  <NormalText style={{color: '#CFDAEC'}}>Total Wallets: {keychain.keys.length}</NormalText>
                  <MediumText style={{color: '#CFDAEC'}}>Linked
                    Wallets: {keychain.keys.filter(key => key.verified).length}</MediumText>
                  <MediumText style={{color: '#CFDAEC'}}>Pending
                    Wallets: {keychain.keys.filter(key => key.status == EStatus.ADDED).length}</MediumText>
                </BalanceContainer>

                <View style={styles.container}>
                  <BigHeaderText>Connected Wallet</BigHeaderText>

                  <WalletCard
                      onPress={handleNavigateToScreen}
                      name={connectedWallet.label}
                      iconUrl=''
                      walletAddress={connectedWallet.address.toString()}
                      status={EStatus.LINKED}
                  />
                </View>

                <View style={styles.container}>
                  <BigHeaderText>Wallets</BigHeaderText>

                  {
                    keychain.keys.map(key => {
                      return (
                          <WalletCard
                              onPress={handleNavigateToScreen}
                              name={key.label}
                              iconUrl=''
                              walletAddress={key.walletAddress.toString()}
                              status={key.status}
                              key={key.walletAddress.toString()}
                          />
                      );
                    })
                  }

                </View>
              </>
              : <NoData text='No wallet Linked' style={{paddingTop: 120}}/>
          }

          <View style={styles.buttonWrapper}>
            <WhiteButton
                label='Add wallet'
                onPress={() => consoleLog('Add new wallet')}
                style={{
                  borderRadius: BRADIUS.MD,
                  width: 240
                }}
                icon
            />
          </View>
        </ScrollView>
      </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    gap: 12,
    marginTop: 32
  },
  walletTitle: {
    fontSize: 24,
    lineHeight: 36,
    color: '#030E20'
  },
  buttonWrapper: {
    marginTop: 50,
    marginBottom: 32,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

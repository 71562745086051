import { RouteProp, useRoute } from "@react-navigation/native";
import { CustomInput } from "components/CustomInput";
import { BlackButton } from "components/ui/button";
import { ScreenContainer } from "components/ui/container";
import { HeaderText, MediumText } from "components/ui/text";
import { EInputOption } from "constants/notifications";
import { BRADIUS, COLORS, PADDING, SPACING } from "constants/theme";
import React, { useCallback, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

interface INotifyProps {}

export const Notify: React.FC<INotifyProps> = () => {
    const [ inputOption, setInputOption ] = useState(EInputOption.PERCENTAGE)
    const [ trigger, setTrigger ] = useState('')
    const [ value, setValue ] = useState({
        percentage: '',
        figure: ''
    })

    const route = useRoute<RouteProp<{params: {params: string}}>>()

    const handleChangeInput = useCallback((text: string, name?: string) => {
        setValue(value => {
            return {
                ...value,
                [name || '']: text
            }
        })
    }, [setValue])

    const handleSubmitTrigger = useCallback(() => {
        setTrigger(inputOption === EInputOption.PERCENTAGE ? value.percentage : value.figure)
    }, [setTrigger, value])

    const handleChangeInputOptionToFigure = useCallback(() => {
        setInputOption(EInputOption.FIGURE)
    }, [setInputOption])

    const handleChangeInputOptionToPercentage = useCallback(() => {
        setInputOption(EInputOption.PERCENTAGE)
    }, [setInputOption])

    console.log({trigger})

    return (
        <ScreenContainer>
            <HeaderText style={{fontWeight: '600', maxWidth: '80%'}}>
                Notify me when Balance {route.params.params === 'Increment' ? 'increases' : 'decreases'}
            </HeaderText>

            <View style={styles.buttonsWrapper}>
                <TouchableOpacity 
                    onPress={handleChangeInputOptionToPercentage}
                    style={{
                        ...styles.button,
                        backgroundColor: inputOption === EInputOption.PERCENTAGE ? 'rgba(0, 0, 0, 0.8)' : 'rgba(220, 220, 220, 0.42)'
                    }}
                >
                    <MediumText 
                        style={{
                            color: inputOption === EInputOption.PERCENTAGE ? COLORS.WHITE : '#9A999F' 
                        }}
                    >
                        By percentage
                    </MediumText>
                </TouchableOpacity>

                <TouchableOpacity 
                    onPress={handleChangeInputOptionToFigure}
                    style={{
                        ...styles.button,
                        backgroundColor: inputOption === EInputOption.FIGURE ? 'rgba(0, 0, 0, 0.8)' : 'rgba(220, 220, 220, 0.42)'
                    }}
                >
                    <MediumText
                        style={{
                            color: inputOption === EInputOption.FIGURE ? COLORS.WHITE : '#9A999F' 
                        }}
                    >
                        By exact figure
                    </MediumText>
                </TouchableOpacity>
            </View>

            <MediumText style={{color: '#1B1C1D', marginTop: 80}}>
                When balance {route.params.params === 'Increment' ? 'increases' : 'decreases'} by
            </MediumText>

            <CustomInput 
                value={inputOption === EInputOption.PERCENTAGE ? value.percentage : value.figure} 
                onChangeText={handleChangeInput}
                placeholder={inputOption === EInputOption.PERCENTAGE
                    ? 'How many percentage %'
                    : 'Enter amount here '
                }
                name={inputOption === EInputOption.PERCENTAGE
                    ? 'percentage'
                    : 'figure'
                }
            />

            <MediumText style={{color: '#667085'}}>
                Hint: You will be notified when your balance 
                {route.params.params === 'Increment' ? ' increases ' : ' decreases '} 
                by the set {inputOption === EInputOption.PERCENTAGE ? 'percentage' : 'amount'}.
            </MediumText>

            <View style={{width: '100%', alignItems: 'center'}}>
                <BlackButton 
                    label='Save trigger' 
                    onPress={handleSubmitTrigger}
                    style={{marginTop: 140}}
                />
            </View>
        </ScreenContainer>
    )
}

const styles = StyleSheet.create({
    buttonsWrapper: {
        gap: 35,
        marginTop: SPACING.LG,
        flexDirection: 'row'
    },
    button: {
        paddingTop: PADDING.SM,
        paddingBottom: PADDING.SM,
        paddingLeft: PADDING.MD,
        paddingRight: PADDING.MD,
        borderRadius: BRADIUS.MD,
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
})
import { BackIcon } from "assets/icons/BackIcon";
import { CircleContainer } from "components/ui/container";
import { NormalText, ThinText } from "components/ui/text";
import { BRADIUS, COLORS, PADDING, SPACING } from "constants/theme";
import React from "react";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";

interface IWalletAddressCardProps {
    name: string
    text?: string
    onPress?: () => void
}

export const WalletAddressCard: React.FC<IWalletAddressCardProps> = ({ name, text, onPress }) => {
    return (
        <TouchableOpacity style={styles.wrapper} onPress={onPress}>
            <View style={styles.main}>
                <CircleContainer style={{width: 32, height: 32}} />

                <View>
                    <NormalText style={{color: '#030E20'}}>{name}</NormalText>
                    {text && <ThinText style={{color: '#5F5F5F'}}>{text}</ThinText>}
                </View>
            </View>

            <BackIcon width={16} height={16} style={{transform: 'rotate(180deg)'}} />
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        paddingBottom: PADDING.MD,
        paddingTop: PADDING.MD,
        paddingLeft: PADDING.XL,
        paddingRight: PADDING.XL,
        borderWidth: 1,
        borderColor: COLORS.BLACK,
        borderRadius: BRADIUS.MD,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 60
    },
    main: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: SPACING.LG
    }
})
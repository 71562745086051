import { HeaderText } from 'components/ui/text';
import React, { CSSProperties } from 'react';
import { View, Text, StyleSheet } from 'react-native';

interface INoDataProps {
    text: string
    style?: Partial<Record<keyof CSSProperties, any>>
}

export const NoData: React.FC<INoDataProps> = ({ text, style={} }) => {
    return (
        <View style={{...styles.wrapper, ...style}}>
            <View style={styles.emptyContainer}></View>
            <HeaderText>{text}</HeaderText>
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 26,
    },
    emptyContainer: {
        width: 91,
        height: 91,
        backgroundColor: '#D9D9D9',
        borderRadius: 50
    }
})
import { CommonActions, useNavigation } from '@react-navigation/native';
import { ConditionCard } from 'components/ConditionCard';
import { ScreenContainer } from 'components/ui/container';
import { HeaderText } from 'components/ui/text';
import { ERoute } from 'constants/routes';
import { SPACING } from 'constants/theme';
import React, { useCallback } from 'react';
import { View } from 'react-native';

interface ICustomizeNotificationsProps {}

export const CustomizeNotifications: React.FC<ICustomizeNotificationsProps> = () => {
    const navigation = useNavigation()

    const handleNavigateToScreen = useCallback((title: string) => {
        navigation.dispatch({...CommonActions.navigate(ERoute.NOTIFY, {params: title})})
    }, [navigation])
    
    return (
        <ScreenContainer>
            <HeaderText style={{fontWeight: '600'}}>Set conditions for your notification triggers</HeaderText>

            <View style={{gap: SPACING.XL, marginTop: 30}}>
                <ConditionCard
                    title='Increment'
                    action='increase'
                    onPress={handleNavigateToScreen}
                />
                <ConditionCard
                    title='Decrease'
                    action='decrease'
                    onPress={handleNavigateToScreen}
                />
            </View>
        </ScreenContainer>
    )
}
import { BackIcon } from "assets/icons/BackIcon";
import { CircleContainer } from "components/ui/container";
import { MediumText, ThinText } from "components/ui/text";
import { BRADIUS, COLORS, PADDING, SPACING } from "constants/theme";
import React, { useCallback } from "react";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";

interface IConditionCardProps {
    title: string
    action: string
    onPress: (title: string) => void
}

export const ConditionCard: React.FC<IConditionCardProps> = ({ title, action, onPress }) => {
    const handleNavigateWithParams = useCallback(() => {
        onPress(title)
    }, [onPress, title])

    return (
        <TouchableOpacity style={styles.wrapper} onPress={handleNavigateWithParams}>
            <View style={styles.infoWrapper}>
                <CircleContainer style={{backgroundColor: 'rgba(220, 53, 69, 0.17)', width: 32, height: 32}} />

                <View>
                    <MediumText style={{fontWeight: '600'}}>{title}</MediumText>
                    <ThinText style={{color: '#2D3645'}}>Track {action} in your account balance</ThinText>
                </View>
            </View>

            <BackIcon style={{transform: 'rotate(180deg)'}} />
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        padding: PADDING.XL,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#F9F8F8',
        borderRadius: BRADIUS.MD
    },
    infoWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: SPACING.LG
    }
})
import * as React from 'react';

//Components
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { NormalText } from 'components/ui/text';

//Data
import { toastsAtom, ToastsState, NOTI_STATUS } from '../../_state/toasts';
import { useToastsActions } from '../../_actions/toasts';

//Styles
import * as Theme from 'constants/theme';
import { useRecoilValue } from 'recoil';

export const Toasts = () => {

  const toasts = useRecoilValue<ToastsState>(toastsAtom);
  const toastActions = useToastsActions();

  React.useEffect(() => {
    toasts.toasts.forEach(({ id }) => {
      setTimeout(() => {
        removeToast(id)
      }, 3000);
    });
  }, [toasts.toasts.length])

  const backgroundStyle = (type: NOTI_STATUS) => {
    switch(type){
      case NOTI_STATUS.SUCCESS: return "green"
      case NOTI_STATUS.ERR: return "red"
      default: return "gray"
    }
  };

  const removeToast = (id: string) => toastActions.removeToast(id);


  return (
    <View style={styles.con}>
      {toasts.toasts.map(({ id, text, type }) => (
        <TouchableOpacity onPress={() => removeToast(id)} key={id} style={[styles.toastCon]}>
          <View style={[styles.innerCon, { backgroundColor: backgroundStyle(type)}]}>
            <NormalText style={{ color: "#FFFFFF" }}>{text}</NormalText>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  )
};


export default Toasts;

const styles = StyleSheet.create({
  con: {
    position: 'absolute',
    bottom: 70,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    zIndex: 999
  },
  toastCon: {
    marginBottom: 5
  },
  innerCon: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 3,
    width: '100%',
    maxWidth: 400,
    padding: 10
  },
  hideCon: {
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 3,
    borderColor: "#FFFFFF",
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

import {MediumText, NormalText} from 'components/ui/text';
import {BRADIUS, PADDING, SPACING} from 'constants/theme';
import React from 'react';
import {StyleSheet, View} from "react-native";

interface INotificationListItemProps {
  message: string
  time: string
  action: string
}

export const NotificationListItem: React.FC<INotificationListItemProps> = ({message, time, action}) => {
  return (
      <View style={styles.wrapper}>
        <NormalText style={{color: '#35333F'}}>{message}</NormalText>

        <View style={styles.description}>
          <MediumText style={{color: '#9A999F'}}>{time}</MediumText>

          <View style={styles.textWrapper}>
            <MediumText style={{color: '#9A999F'}}>{action}</MediumText>
          </View>
        </View>
      </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    gap: SPACING.LG,
    paddingTop: PADDING.XL,
    paddingBottom: PADDING.XL,
    borderBottomWidth: 1,
    borderBottomColor: '#CDCCCF'
  },
  description: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textWrapper: {
    backgroundColor: 'rgba(220, 220, 220, 0.42)',
    borderRadius: BRADIUS.MD,
    paddingTop: PADDING.SM,
    paddingBottom: PADDING.SM,
    paddingLeft: PADDING.MD,
    paddingRight: PADDING.MD,
  }
})

import {PublicKey} from "@solana/web3.js";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {consoleLog} from "../_helpers/debug";

import {useWallet} from "@solana/wallet-adapter-react";
import {stacheAtom} from "../_state/stache-state";
import {useKeychainActions} from "./keychain-actions";
import {solanaAtom} from "../_state/solana";
import {IStache, Solana} from "../_state/state-types";
import {AccountResponse, SKeychain, SStache} from "../constants/apis/solana-types";
import {useWalletActions} from "./wallet-actions";
import {walletAtom} from "../_state/wallet-state";

function useStacheActions() {

  const [stache, setStache] = useRecoilState(stacheAtom);
  const resetStacheState = useResetRecoilState(stacheAtom);
  const walletActions = useWalletActions();
  const [wallet, setWallet] = useRecoilState(walletAtom);
  const keychainActions = useKeychainActions();

  const {sendTransaction} = useWallet();

  const solana: Solana = useRecoilValue(solanaAtom);

  // first checks to see if a keychain for this wallet exists, then pulls the stacheid from the account and loads
  // the stache
  async function checkForStache(walletAddress: PublicKey = null): Promise<IStache> {

    // mark the wallet as checked
    const walletState = {...wallet, checkedForStache: true};
    let walletLinked = false;
    const solanaClient = solana.solanaClient;
    const keychainResponse: AccountResponse<SKeychain> = await solanaClient.getKeychainByKeyWallet(walletAddress);
    let stacheState = null;
    if (keychainResponse.account) {
      const stacheid = keychainResponse.account.name;
      const stacheResponse = await solanaClient.getStacheById(stacheid, keychainResponse.account.domainPda);
      if (stacheResponse.account) {

        // then we set the keychain & the stache
        const keychainState = keychainActions.setKeychainState(keychainResponse);
        stacheState = createStacheState(stacheResponse.pda, stacheResponse.account);

        // now set the stache state
        setStache(stacheState)
        walletLinked = keychainState.walletLinked;
      }
    }
    walletState.walletLinked = walletLinked;

    // update the wallet state
    setWallet(walletState);

    return stacheState;
  }

  async function createStache(stacheid: string, walletAddress: PublicKey): Promise<IStache> {

    // todo: stacheid validation: 3 >= length <= 32, all alphanumeric only (for now)
    stacheid = stacheid.toLowerCase().trim();

    const solanaClient = solana.solanaClient;

    // create the stache
    const stacheResponse: AccountResponse<SStache> = await solanaClient.createStache(stacheid, walletAddress, sendTransaction);

    consoleLog(`stache response: ${JSON.stringify(stacheResponse)}`);
    if (stacheResponse.account) {

      consoleLog(`stache created for stacheid ${stacheid}: pda: ${stacheResponse.pda} with keychain: ${stacheResponse.account.keychain}`);

      const stache = stacheResponse.account;
      // then a keychain was also created. grab it
      const keychainResponse = await solanaClient.getKeychainByKeyWallet(stache.keychainPda);

      // set the keychain and stache states
      const keychainState = keychainActions.setKeychainState(keychainResponse);
      const stacheState = createStacheState(stacheResponse.pda, stache);
      setStache(stacheState);
      consoleLog(`create and set the keychain state: ${keychainState} and stache state: ${stacheState}`);
      return stacheState;

    } else {
      return null;
    }
  }

  function createStacheState(pda: PublicKey, account: any): IStache {
    return {
      pda,
      avatarUrl: null,
      stacheid: account.name,
    }
  }

  function resetStache(): boolean {
    resetStacheState();
    return true;
  }

  return {
    checkForStache,
    createStache,
    resetStache,
  }
}

export {useStacheActions}


import { CommonActions, useNavigation } from "@react-navigation/native";
import { SearchBook } from "components/SearchBook";
import { ScreenContainer } from "components/ui/container";
import { ERoute } from "constants/routes";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

interface IAddressBookProps {}

export const AddressBook: React.FC<IAddressBookProps> = () => {
    const [ searchTerm, setSearchTerm ] = useState('')
    const navigation = useNavigation()
    
    const handleNavigateToScreen = useCallback(() => {
        navigation.dispatch({...CommonActions.navigate(ERoute.SELECT_USER)})
    }, [navigation])
    
    return (
        <ScreenContainer style={{paddingBottom: 96}}>
            <ScrollView>
            <View style={{gap: 56}}>
                <SearchBook 
                    value={searchTerm} 
                    onChangeText={setSearchTerm} 
                    onPress={handleNavigateToScreen} 
                    />
            </View>
            </ScrollView>
        </ScreenContainer>
    )
}
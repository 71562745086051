import {Button} from 'components/Button';
import {CustomModal} from 'components/CustomModal';
import React from 'react';
import {Image, StyleSheet, Text} from "react-native";
import {Asset} from 'expo-asset';
import {useAuthActions} from "../../_actions/auth-actions";
import {useWallet} from "@solana/wallet-adapter-react";
import useToasts from "../../hooks/useToasts";
import {NOTI_STATUS} from "../../_state/toasts";
import {LoginResponse} from "../../constants/apis/apiclient";
import { NormalText } from 'components/ui/text';

const checkedImage = Asset.fromModule(require('../../assets/checked.png')).uri
const notCheckedImage = Asset.fromModule(require('../../assets/not-checked.png')).uri

interface IWalletModalProps {
  isOpen: boolean
  text?: string
  button?: boolean
  buttonLabel?: string
  close?: () => void
  handleNavigate?: () => void
  handleRegister?: () => void
}

export const WalletConnectedModal: React.FC<IWalletModalProps> = ({
                                                                    isOpen,
                                                                    text,
                                                                    close,
                                                                    button,
                                                                    buttonLabel,
                                                                    handleNavigate
                                                                  }) => {

  const authActions = useAuthActions();
  const {signMessage} = useWallet();
  const {createToast} = useToasts();

  async function login() {

    try {
      const loginResponse: LoginResponse = await authActions.login(signMessage);
      createToast(`Welcome to your Stache Smart Wallet ${loginResponse.stache.stacheid}`, NOTI_STATUS.SUCCESS);
      // now we go to the home screen
      handleNavigate();
    } catch (err) {
      createToast(err.message, NOTI_STATUS.ERR);
    }
  }

  return (
      <CustomModal
          isOpen={isOpen}
          close={close}
          style={{gap: 32}}
      >
        <Image source={{uri: checkedImage}} style={styles.image}/>
        <NormalText>{text}</NormalText>
        {button &&
           <Button
              label='Login'
              onPress={login}
              styleContainer={styles.buttonBlack}
              styleLabel={styles.buttonLabel}
           />
        }
        {/* todo milena: this button needs to go back to landing page and call the authActions.logout() */}
        <Button
            label={buttonLabel || 'Cancel'}
            onPress={handleNavigate}
            styleContainer={styles.buttonBlack}
            styleLabel={styles.buttonLabel}
        />
      </CustomModal>
  )
}

export const WalletNotLinkedModal: React.FC<IWalletModalProps> = ({
                                                                    isOpen,
                                                                    close,
                                                                    handleNavigate,
                                                                    handleRegister
                                                                  }) => {
  return (
      <CustomModal
          isOpen={isOpen}
          close={close}
          style={{gap: 12}}
      >
        <Image source={{uri: notCheckedImage}} style={styles.image}/>
        <NormalText>Wallet not linked to stache account</NormalText>
        <Button
            label='Link wallet to existing Stache'
            onPress={handleNavigate}
            styleContainer={{width: 206}}
        />
        <Button
            label='Create new Stache'
            onPress={handleRegister}
            styleContainer={{...styles.buttonBlack, width: 206}}
            styleLabel={styles.buttonLabel}
        />
        <Button
            label='Cancel (this button for testing)'
            onPress={close}
            styleContainer={{...styles.buttonBlack, width: 206}}
            styleLabel={styles.buttonLabel}
        />
      </CustomModal>
  )
}

// export const WalletVerificationModal: React.FC<IWalletModalProps> = ({ isOpen }) => {
//     return (
//         <CustomModal isOpen={isOpen}></CustomModal>
//     )
// }

const styles = StyleSheet.create({
  image: {
    width: 108,
    height: 108
  },
  buttonBlack: {
    backgroundColor: '#090909',
    borderWidth: 0
  },
  buttonLabel: {
    color: '#FAFAFA'
  }
})

export enum ERoute {
  CONNECT_WALLET = 'ConnectWallet',
  LINK_WALLET = 'LinkWallet',
  CREATE_NEW_STACHE = 'CreateNewStache',
  HOMEPAGE = 'Home',
  NOTIFICATIONS = 'Notifications',
  CUSTOMIZE_NOTIFICATIONS = 'CustomizeNotifications',
  NOTIFY = 'Notify',
  SETTINGS = 'Settings',
  WALLET = 'Wallet',
  HOME_ASSETS = 'HomeAssets',
  WALLET_ASSETS = 'WalletAssets',
  HOME_COLLECTIBLES = 'HomeCollectibles',
  WALLET_COLLECTIBLES = 'WalletCollectibles',
  HISTORY = 'History',
  MANAGE_WALLETS = 'ManageWallets',
  DELETE_WALLET = 'DeleteWallet',
  ASSETS_DETAILS = 'AssetsDetails',
  DETAILS = 'Details',
  ASSETS_HISTORY = 'AssetsHistory',
  STASHING = 'Stashing',
  SEARCH_ADDRESS = 'SearchAddress',
  SENDING = 'Sending',
  CONNECTED = 'Connected',
  LINKED = 'Linked',
  STACHE_WALLET = 'StacheWallet',
  CONNECTED_WALLET = 'ConnectedWallet',
  ADDRESS_BOOK = 'AddressBook',
  SELECT_USER = 'SelectUser',
  EDIT_USER = 'EditUser',
  ADD_USER = 'AddUser',
  NEW_USER = 'NewUser',
  CONFIRM_PAYMENT = 'ConfirmPayment',
  FAQ = 'FAQ',
  FAQ_INFO = 'FAQInfo',
}

import React, {useCallback, useEffect, useState} from 'react';
import {ScrollView, StyleSheet, TouchableOpacity, View} from "react-native";
import Toasts from "components/Toasts";
import {useRecoilState, useRecoilValue} from 'recoil';
import {Avatar} from 'components/Avatar';
import {BellIcon} from 'assets/icons/BellIcon';
import {TabsHeader} from 'components/TabsHeader';
import {Tabs} from 'components/Tabs';
import {ERoute} from 'constants/routes';
import {CommonActions, useNavigation} from '@react-navigation/native';
import {COLORS, SPACING} from "../../constants/theme";
import {EOption} from 'constants/dropdown';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {ConnectedWallet, StacheWallet} from './Wallets';
import {BalanceContainer, ScreenContainer} from 'components/ui/container';
import {homepageAtom} from '../../_state/screen-props';
import {NormalText} from 'components/ui/text';
import {consoleLog} from '../../_helpers/debug';
import {useStacheWalletActions} from "../../_actions/stachewallet-actions";
import {useConnectedWalletActions} from "../../_actions/connectedwallet-actions";
import {stacheAtom} from '../../_state/stache-state';

interface IHomeProps {
}

export const Home: React.FC<IHomeProps> = () => {
  const Tab = createNativeStackNavigator()
  const [, setHomepageProps] = useRecoilState(homepageAtom)
  const [dropdownValue, setDropdownValue] = useState(EOption.MY_ASSETS)
  const stache = useRecoilValue(stacheAtom)
  const stacheWalletActions = useStacheWalletActions();
  const connectedWalletActions = useConnectedWalletActions();

  const navigation = useNavigation();

  const handleNavigateToScreen = useCallback(() => {
    navigation.dispatch({...CommonActions.navigate(ERoute.NOTIFICATIONS)})
  }, [navigation])

  const handleChangeDropdownValue = useCallback((value: EOption) => {
    setDropdownValue(value)
    setHomepageProps(homepageProps => {
      return {
        ...homepageProps,
        asset: value === EOption.MY_ASSETS
      }
    })
  }, [setDropdownValue])

  const updateExistingRoute = useCallback((route: ERoute) => {
    setHomepageProps(homepageProps => {
      return {
        ...homepageProps,
        stache: route === ERoute.STACHE_WALLET
      }
    })
  }, [setHomepageProps])

  useEffect(() => {
    consoleLog('calling stachewallet load');
    stacheWalletActions.load();
    consoleLog('calling connectedwallet load');
    connectedWalletActions.load();
    consoleLog('stachewallet load done');
  }, []);

  return (
      <ScreenContainer style={{paddingBottom: 96}}>
        {/* todo: bit of a hack here..? on logout this bombs since the stache becomes null*/}
        {stache && stache.pda &&
           <ScrollView>
              <View style={{gap: SPACING.XXXL}}>
                 <View style={{...styles.userWrapper, marginTop: 8}}>
                    <View style={styles.user}>
                       <Avatar iconUrl={stache.avatarUrl} username={stache.stacheid}
                               onPress={() => consoleLog('Change avatar')}/>

                       <View>
                          <NormalText style={{lineHeight: 16}}>@{stache.stacheid}</NormalText>
                          <NormalText style={{lineHeight: 16}}>{stache.pda.toString()}</NormalText>
                       </View>
                    </View>

                    <TouchableOpacity onPress={handleNavigateToScreen}>
                       <BellIcon/>
                    </TouchableOpacity>
                 </View>

                 <BalanceContainer style={{alignItems: 'flex-start', padding: 24}}>
                    <NormalText style={{color: COLORS.MAIN_TEXT_LIGHT}}>Stache Balance:</NormalText>
                    <NormalText style={{maxWidth: '45%', color: COLORS.MAIN_TEXT_LIGHT}}>Connected Wallet
                       Balance:</NormalText>
                 </BalanceContainer>

                 <TabsHeader
                    title={dropdownValue === EOption.MY_ASSETS ? 'My Tokens' : 'My Collectibles'}
                    options={[
                      {name: EOption.MY_ASSETS},
                      {name: EOption.MY_COLLECTIBLES}
                    ]}
                    styleDropdown={{minWidth: 96}}
                    onChange={handleChangeDropdownValue}
                    value={dropdownValue}
                 />

                 <View>
                    <Tabs
                       setRoute={updateExistingRoute}
                       tabs={[
                         {
                           name: 'Stache wallet',
                           route: ERoute.STACHE_WALLET
                         },
                         {
                           name: 'Connected wallet',
                           route: ERoute.CONNECTED_WALLET
                         }
                       ]}
                    />

                    <Tab.Navigator initialRouteName={ERoute.STACHE_WALLET}>
                       <Tab.Screen
                          name={ERoute.STACHE_WALLET}
                          options={{headerShown: false}}
                       >
                         {(props) => <StacheWallet {...props}
                                                   route={dropdownValue === EOption.MY_ASSETS ? ERoute.HOME_ASSETS : ERoute.HOME_COLLECTIBLES}/>}
                       </Tab.Screen>

                       <Tab.Screen name={ERoute.CONNECTED_WALLET} options={{headerShown: false}}>
                         {(props) => <ConnectedWallet {...props}
                                                      route={dropdownValue === EOption.MY_ASSETS ? ERoute.HOME_ASSETS : ERoute.HOME_COLLECTIBLES}/>}
                       </Tab.Screen>
                    </Tab.Navigator>
                 </View>

                 <Toasts/>
              </View>
           </ScrollView>
        }
      </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  userWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  user: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: SPACING.MD
  }
});

import {isDev} from "../constants/config";

export function consoleLog() {
  if (isDev()) {
    // eslint-disable-next-line prefer-rest-params
    const args = [...arguments];
    for (let i = 0; i < args.length; i += 1) {
      // eslint-disable-next-line no-console
      console.log(args[i]);
    }
  }
}

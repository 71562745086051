import { Search } from "components/Search";
import { SubHeaderText } from "components/ui/text";
import { WalletAddressCard } from "components/WalletAddressCard";
import { SPACING } from "constants/theme";
import React from "react";
import { View, Text, StyleSheet } from "react-native";

interface ISearchBookProps {
    value: string
    onChangeText: (text: string) => void
    onPress: () => void
}

export const SearchBook: React.FC<ISearchBookProps> = ({ value, onChangeText, onPress }) => {
    return (
        <>
            <Search 
                placeholder='Search by @stache tag or sol wallet address' 
                value={value} 
                onChangeText={onChangeText}
            />

            <View style={styles.addressWrapper}>
                <SubHeaderText style={{color: '#4B4B4B'}}>Saved Addresses</SubHeaderText>
                <WalletAddressCard name='Wallet address....' text='label' onPress={onPress}/>
                <WalletAddressCard name='Wallet address....' text='@StacheID' onPress={onPress}/>
                <WalletAddressCard name='@Stache username....' onPress={onPress}/>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    addressWrapper: {
        gap: SPACING.LG
    },
})
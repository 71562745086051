import {CommonActions, useNavigation} from '@react-navigation/native';
import {QuestionIcon} from 'assets/icons/QuestionIcon';
import {SignOutIcon} from 'assets/icons/SignOutIcon';
import {Avatar} from 'components/Avatar';
import {SettingsCard} from 'components/SettingsCard';
import {ScreenContainer} from 'components/ui/container';
import {HeaderText} from 'components/ui/text';
import {ERoute} from 'constants/routes';
import {SPACING} from 'constants/theme';
import React, {useCallback} from 'react';
import {StyleSheet, View} from "react-native";
import {useAuthActions} from "../../_actions/auth-actions";

interface ISettingsProps {
}

export const Settings: React.FC<ISettingsProps> = () => {
  const navigation = useNavigation();
  const authActions = useAuthActions();

  const handleNavigateToScreen = useCallback((route: ERoute) => {
    return () => navigation.dispatch({...CommonActions.navigate(route)})
  }, [navigation])

  const handleLogOut = useCallback(async () => {
    await authActions.logout();
    navigation.dispatch({...CommonActions.navigate(ERoute.CONNECT_WALLET)});
    // logout functionality
  }, [])

  return (
      <ScreenContainer style={{alignItems: 'center'}}>
        <View style={{width: '100%', alignItems: 'center'}}>
          <Avatar style={styles.avatar} iconUrl=""/>

          <View>
            <HeaderText
                style={{
                  fontSize: 24,
                  lineHeight: 36,
                  fontWeight: '600',
                  marginTop: 12,
                }}
            >
              Stacheid
            </HeaderText>
          </View>
        </View>

        <View style={styles.cardsWrapper}>
          <SettingsCard
              text='Help & FAQ'
              onPress={handleNavigateToScreen(ERoute.FAQ)}
              icon={<QuestionIcon/>}
          />
          <SettingsCard
              text='Logout'
              onPress={handleLogOut}
              icon={<SignOutIcon/>}
          />
        </View>
      </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  avatar: {
    width: 80,
    height: 80,
  },
  cardsWrapper: {
    width: '100%',
    marginTop: SPACING.XXL,
    gap: SPACING.XXL
  }
})

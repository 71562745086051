import { BRADIUS, COLORS, PADDING, SPACING } from 'constants/theme';
import React, { PropsWithChildren } from 'react';
import { ScrollView, StyleProp, StyleSheet, View } from 'react-native';

interface Props extends PropsWithChildren {
  style?: StyleProp<any>
}

export const ScreenContainer: React.FC<Props> = (props) : React.ReactElement => <View style={[styles.screenWrapper, props.style]}>{props.children}</View>
export const BalanceContainer: React.FC<Props> = (props) : React.ReactElement => <View style={[styles.balanceWrapper, props.style]}>{props.children}</View>
export const CircleContainer: React.FC<Props> = (props) : React.ReactElement => <View style={[styles.circleWrapper, props.style]}>{props.children}</View>

const styles = StyleSheet.create({
  screenWrapper: {
    flex: 1,
    padding: PADDING.XL,
    backgroundColor: COLORS.WHITE,
  },
  balanceWrapper: {
    alignItems: 'center',
    width: '100%',
    gap: SPACING.LG,
    padding: PADDING.XL,
    backgroundColor: COLORS.BLACK,
    borderRadius: BRADIUS.XL
  },
  circleWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 36,
    height: 36,
    borderRadius: BRADIUS.ROUND,
    backgroundColor: COLORS.DEFAULT_CIRCLE
  }
})
import {NotificationListItem} from "components/NotificationListItem";
import {ScreenContainer} from "components/ui/container";
import React, {useCallback} from "react";
import {Image, StyleSheet, View} from "react-native";
import {Asset} from 'expo-asset';
import {SPACING} from "constants/theme";
import {BlackButton, WhiteButton} from "components/ui/button";
import {CommonActions, useNavigation} from "@react-navigation/native";
import {ERoute} from "constants/routes";
import {useRecoilState} from "recoil";
import {notificationsAtom} from "../../_state/notifications-state";
import {uniqueId} from "lodash";
import {NormalText, SubHeaderText} from "components/ui/text";

const noNotificationImage = Asset.fromModule(require('../../assets/no-notification.png')).uri

interface INotificationsProps {
}

export const Notifications: React.FC<INotificationsProps> = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsAtom)

  const navigation = useNavigation()

  const handleNavigateToHomepage = useCallback(() => {
    navigation.dispatch({...CommonActions.navigate(ERoute.HOMEPAGE)})
  }, [navigation])

  const handleClearNotifications = useCallback(() => {
    setNotifications([])
  }, [setNotifications])

  return (
      <ScreenContainer style={{alignItems: 'center', paddingTop: 0}}>
        {notifications
            ? <View style={{flex: 1, justifyContent: 'space-between', width: '100%'}}>
              <View>
                {notifications.map(notification => {
                  return (
                      <NotificationListItem
                          key={uniqueId()}
                          message={notification.message}
                          time={notification.time}
                          action={notification.action}
                      />
                  )
                })}
              </View>
              <View style={{width: '100%', alignItems: 'center'}}>
                <WhiteButton
                    label='Clear all'
                    onPress={handleClearNotifications}
                    style={{marginBottom: 40}}
                />
              </View>
            </View>

            : <View style={{gap: SPACING.XXXL, alignItems: 'center', marginTop: 110}}>
              <Image source={{uri: noNotificationImage}} style={styles.image}/>

              <SubHeaderText style={{color: '#35333F', fontWeight: '600'}}>You have no notifications yet
                !</SubHeaderText>

              <NormalText style={{color: '#68666F', maxWidth: 330, textAlign: 'center'}}>
                All your notifications will be saved here for you to access their state anytime.
              </NormalText>

              <BlackButton label='Back to home' onPress={handleNavigateToHomepage}/>
            </View>
        }
      </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  image: {
    width: 168,
    height: 168
  }
})

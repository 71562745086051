import { ThinText } from 'components/ui/text';
import { BRADIUS, COLORS, SPACING } from 'constants/theme';
import React, { CSSProperties, PropsWithChildren } from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';

interface IButtonProps extends PropsWithChildren {
    label: string
    onPress: () => void
    styleContainer?: Partial<Record<keyof CSSProperties, any>>
    styleLabel?: Partial<Record<keyof CSSProperties, any>>
}

export const Button: React.FC<IButtonProps> = ({ label, onPress, children, styleContainer={}, styleLabel={} }) => {
    return (
        <TouchableOpacity 
            onPress={onPress} 
            style={{...styles.button, ...styleContainer}}
        >
            <ThinText style={{fontWeight: '600', ...styleLabel}}>
                {label}
            </ThinText>
            {children}
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    button: {
        padding: 10,
        borderRadius: BRADIUS.XXXL,
        borderWidth: 1,
        borderColor: COLORS.BLACK,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: SPACING.MD,
        minWidth: 160,
    }
})
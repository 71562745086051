import { BigHeaderText } from 'components/ui/text';
import React from 'react';
import { StyleSheet, Text, Image, TouchableOpacity } from 'react-native';

interface IAvatarProps {
    iconUrl: string
    username?: string
    onPress?: () => void
    style?: any
}

export const Avatar: React.FC<IAvatarProps> = ({ iconUrl, username, onPress, style={} }) => {
    return (
        <TouchableOpacity style={{...styles.wrapper, ...style}} onPress={onPress}>
            {iconUrl 
                ? <Image source={{uri: iconUrl}} style={styles.image} /> 
                : <BigHeaderText style={{fontSize: 28}}>{username ? username[0].toUpperCase() : ''}</BigHeaderText>
            }
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 36,
        height: 36,
        borderRadius: 50,
        backgroundColor: '#D9D9D9'
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: 50
    }
})
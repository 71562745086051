import {atom, selector} from 'recoil';
import {IKey, IKeychain} from "./state-types";
import {walletAtom} from "./wallet-state";


export const keychainAtom = atom<IKeychain>({
  key: 'keychain',
  default: {
    pda: null,
    name: '',
    walletLinked: false,
    keys: [] as IKey[],
  }
})

export const connectedKeySelector = selector({
  key: 'connectedKey',
  get: ({get}) => {
    const keychain = get(keychainAtom);
    const wallet = get(walletAtom);
    return keychain.keys.find(key => key.walletAddress.equals(get(walletAtom).address));
  }
})

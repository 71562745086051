import React from 'react';
import { ComponentProps, CSSProperties } from 'react';
import { Svg, Path } from 'react-native-svg';

interface IBellIconProps extends ComponentProps<'svg'> {
    style?: Partial<Record<keyof CSSProperties, any>>
}

export const BellIcon: React.FC<IBellIconProps> = ({ width, height, style={} }) => {
    return (
        <Svg 
            width={32 || width} 
            height={32 || height} 
            viewBox="0 0 32 32" 
            fill="none"
            style={style}
        >
            <Path 
                d="M7.02502 13.0001C7.02335 11.8146 7.25625 10.6405 7.7103 9.54534C8.16435 8.45021 8.83058 7.45573 9.67061 6.61918C10.5106 5.78263 11.5079 5.12054 12.6049 4.67105C13.7019 4.22156 14.877 3.99354 16.0625 4.00014C21.0125 4.03764 24.975 8.15014 24.975 13.1126V14.0001C24.975 18.4751 25.9125 21.0751 26.7375 22.5001C26.8251 22.6519 26.8713 22.824 26.8715 22.9993C26.8716 23.1745 26.8258 23.3467 26.7384 23.4986C26.651 23.6505 26.5253 23.7768 26.3738 23.8648C26.2223 23.9529 26.0503 23.9995 25.875 24.0001H6.12502C5.94978 23.9995 5.77778 23.9529 5.62625 23.8648C5.47472 23.7768 5.34899 23.6505 5.26163 23.4986C5.17428 23.3467 5.12839 23.1745 5.12854 22.9993C5.1287 22.824 5.1749 22.6519 5.26252 22.5001C6.08752 21.0751 7.02502 18.4751 7.02502 14.0001V13.0001Z"
                stroke="#000" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M12 24V25C12 26.0609 12.4214 27.0783 13.1716 27.8284C13.9217 28.5786 14.9391 29 16 29C17.0609 29 18.0783 28.5786 18.8284 27.8284C19.5786 27.0783 20 26.0609 20 25V24" 
                stroke="#000" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}

export const BellSimpleIcon: React.FC<IBellIconProps> = ({ width, height, style={} }) => {
    return (
        <Svg 
            width={width || 16} 
            height={height || 16} 
            viewBox="0 0 16 16" 
            fill="none"
        >
            <Path 
                d="M6 14H10" 
                stroke="black" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M3.51245 6.50007C3.51161 5.90731 3.62806 5.32024 3.85509 4.77267C4.08211 4.2251 4.41523 3.72786 4.83525 3.30959C5.25526 2.89131 5.75388 2.56027 6.30239 2.33552C6.85089 2.11078 7.43844 1.99677 8.0312 2.00007C10.5062 2.01882 12.4874 4.07507 12.4874 6.55632V7.00007C12.4874 9.23757 12.9562 10.5376 13.3687 11.2501C13.4125 11.3259 13.4356 11.412 13.4357 11.4996C13.4358 11.5872 13.4128 11.6734 13.3691 11.7493C13.3255 11.8253 13.2626 11.8884 13.1868 11.9324C13.1111 11.9764 13.0251 11.9998 12.9374 12.0001H3.06245C2.97483 11.9998 2.88883 11.9764 2.81307 11.9324C2.7373 11.8884 2.67443 11.8253 2.63076 11.7493C2.58708 11.6734 2.56413 11.5872 2.56421 11.4996C2.56429 11.412 2.58739 11.3259 2.6312 11.2501C3.0437 10.5376 3.51245 9.23757 3.51245 7.00007V6.50007Z" 
                stroke="black" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}



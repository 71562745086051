import {PublicKey} from "@solana/web3.js";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {useWalletActions} from "./wallet-actions";
import {walletAtom} from "../_state/wallet-state";
import {findKeychainKeyPda} from "../programs/keychain-utils";
import {consoleLog} from "../_helpers/debug";
import {solanaAtom} from "../_state/solana";
import {keychainAtom} from "../_state/keychain-state";
import {EStatus, IKeychain} from "../_state/state-types";
import {AccountResponse, SKeychain} from "../constants/apis/solana-types";

function useKeychainActions() {

  const [keychain, setKeychain] = useRecoilState(keychainAtom);
  const resetKeychainState = useResetRecoilState(keychainAtom);
  const solana = useRecoilValue(solanaAtom);
  const walletActions = useWalletActions();
  const wallet = useRecoilValue(walletAtom);

  async function checkKeychainByKey(walletAddress: PublicKey): Promise<IKeychain> {

    const solanaClient = solana.solanaClient;
    const keychain = await solanaClient.getKeychainByKeyWallet(walletAddress);
    if (keychain) {
      return setKeychainState(keychain);
    } else {
      return null;
    }
  }

  function setKeychainState(keychainResp: AccountResponse<SKeychain>): IKeychain {
    const keychainState = createKeychainState(keychainResp.pda, keychainResp.account);
    setKeychain(keychainState);
    return keychainState;
  }

  function createKeychainState(accountAddress: PublicKey, account: any): IKeychain {
    let walletVerified = false;
    const keys = account.keys.map(key => {
      const keyPda = findKeychainKeyPda(key.key);
      if (key.key.equals(wallet.address) && key.verified) {
        walletVerified = true;
      }
      return {
        label: key.key.toString(),
        walletAddress: key.key,
        accountAddress: keyPda,
        status: key.verified ? EStatus.LINKED : EStatus.ADDED,
        verified: key.verified
      }
    });
    if (walletVerified) {
      consoleLog('wallet verified', wallet.address.toBase58(), accountAddress.toBase58());
    } else {
      consoleLog('wallet not verified', wallet.address.toBase58(), accountAddress.toBase58());
    }

    return {
      pda: accountAddress,
      name: account.name,
      keys,
      walletLinked: walletVerified,
    };
  }


  async function resetKeychain(disconnectWallet = false): Promise<boolean> {
    if (disconnectWallet) {
      await walletActions.disconnectWallet();
    }
    resetKeychainState();
    return true;
  }

  return {
    checkKeychainByKey,
    resetKeychain,
    setKeychainState
  }
}

export {useKeychainActions}


import { BannerText, ThinText } from "components/ui/text";
import { BRADIUS, COLORS, PADDING, SPACING } from "constants/theme";
import React from "react";
import { View, Text, StyleSheet } from "react-native";

interface ICollectibleCardProps {
    action: string
    name: string
    price: string
    value: string
    style?: any
}

export const CollectibleCard: React.FC<ICollectibleCardProps> = ({ action, name, price, value, style={} }) => {
    return (
        <View style={{...styles.wrapper, ...style}}>
            <ThinText style={{color: '#9A9A9A'}}>You are {action}</ThinText>

            <View style={styles.container}>
                <View style={styles.rectangle}/>

                <View style={styles.main}>
                    <View style={styles.mainRow}>
                        <BannerText>{name}</BannerText>
                        <BannerText>{value}</BannerText>
                    </View>

                    <View style={styles.mainRow}>
                        <ThinText>{name}</ThinText>
                        <BannerText>{price}</BannerText>
                    </View>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        gap: SPACING.MD,
        alignItems: 'flex-start',
    },
    container: {
        padding: PADDING.MD,
        width: '100%',
        borderWidth: 1,
        borderColor: COLORS.BLACK,
        borderRadius: BRADIUS.MD,
        flexDirection: 'row',
        gap: SPACING.LG
    },
    rectangle: {
        backgroundColor: '#D9D9D9',
        width: 52
    },
    main: {
        flex: 1,
        gap: SPACING.LG
    },
    mainRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
})
import { COLORS } from 'constants/theme';
import React from 'react';
import { ComponentProps } from 'react';
import { Svg, Path } from 'react-native-svg';

interface ISignOutIconProps extends ComponentProps<'svg'> {
    size?: number
}

export const SignOutIcon: React.FC<ISignOutIconProps> = ({ size }) => {
    return (
        <Svg 
            width={size || 16} 
            height={size || 16} 
            viewBox="0 0 16 16" 
            fill="none"
        >
            <Path 
                d="M10.875 5.375L13.5 8L10.875 10.625" 
                stroke={COLORS.BLACK}
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M6.5 8H13.5" 
                stroke={COLORS.BLACK}
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M6.5 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V3C2.5 2.86739 2.55268 2.74021 2.64645 2.64645C2.74021 2.55268 2.86739 2.5 3 2.5H6.5" 
                stroke={COLORS.BLACK}
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}

import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {walletAtom} from "../_state/wallet-state";
import {apiClient, LoginResponse} from "../constants/apis/apiclient";
import {solanaAtom} from "../_state/solana";
import {consoleLog} from "../_helpers/debug";
import {stacheAtom} from "../_state/stache-state";
import {PublicKey} from "@solana/web3.js";
import {useKeychainActions} from "./keychain-actions";
import {useWalletActions} from "./wallet-actions";
import {useStacheActions} from "./stache-actions";
import {userProfileAtom} from "../_state/userprofile-state";

function useAuthActions() {

  const keychainActions = useKeychainActions();
  const walletActions = useWalletActions();
  const stacheActions = useStacheActions();
  const wallet = useRecoilValue(walletAtom);
  const userProfileReset = useResetRecoilState(userProfileAtom);
  const resetSolana = useResetRecoilState(solanaAtom);
  const [solana, setSolana] = useRecoilState(solanaAtom);
  const [stache, setStache] = useRecoilState(stacheAtom);


  async function logout(): Promise<boolean> {
    await walletActions.disconnectWallet();
    await keychainActions.resetKeychain();
    stacheActions.resetStache();
    userProfileReset();
    resetSolana();
    //todo: api logout  ..? (just need to invalidate the jwt token, remove from localstorage and from the apiclient)
    return true;
  }

  async function login(signMessage: (message: Uint8Array) => Promise<Uint8Array>): Promise<LoginResponse> {
    // step 1: fetch the nonce
    const nonceResp = await apiClient.getNonce(wallet.address);

    // step 2: sign the nonce
    // Encode nonce
    const message = new TextEncoder().encode(nonceResp.nonce);
    const signMessageResp = await signMessage(message);

    // step 3: send the signed nonce to the server
    const signature = Array.from(signMessageResp);
    const loginResp = await apiClient.login(nonceResp.nonceId, signature, stache.pda);
    consoleLog('login resp >> ', loginResp);

    // todo: save login stuff to local storage (save jwt token, stacheInfo, etc)

    // update our solana client
    const stacheInfo = loginResp.stache;
    consoleLog('stacheInfo >> ', stacheInfo);

    setStache(stache => {
      return {
        ...stache,
        id: stacheInfo.id,
        stacheid: stacheInfo.stacheid,
        avatarUrl: stacheInfo.avatarUrl
      }
    })

    const newSolanaClient = solana.solanaClient.clone();
    newSolanaClient.init(stacheInfo.stacheid, new PublicKey(stacheInfo.stachePda), new PublicKey(stacheInfo.keychainPda));
    setSolana({
      solanaClient: newSolanaClient
    });

    consoleLog('updated initialized solana client');

    // todo: if we get here, we're logged in, so we need to save the user/auth data to recoil, and the auth token to localstorage
    // can see how this is done in domination (vanguard)
    return loginResp;
  }

  return {
    logout,
    login
  }
}

export {useAuthActions}


import {atom} from 'recoil';
import {IStache} from "./state-types";


export const stacheAtom = atom<IStache | null>({
  key: 'stache',
  default: null,
  effects: [
    ({onSet}) => {
      onSet((newStache) => {
        console.log('stache state changed: ', newStache);
      });
    }
  ]
});

import { toastsAtom, ToastsState, NOTI_STATUS } from "../_state/toasts";
import { useRecoilState } from 'recoil';
import { useCallback } from "react";


export const useToasts = () => {

  const [toasts, setToasts] = useRecoilState<ToastsState>(toastsAtom);

  const createToast = (text: string, type: NOTI_STATUS) => {
    const newArray = [...toasts.toasts]; // this is a shallow copy that creates new place in memory but still points to the state (to the previous place in memory)
    newArray.push({ 
      id: (newArray.length + 1).toString(),
      text,
      type,
    }) // and then this directly pushes new data to the old state reference which confuses react
    setToasts({ toasts: newArray });
  }

  //** Here is a better approach */
  // const createToast = useCallback((text: string, type: NOTI_STATUS) => {
  //   setToasts(toasts => {
  //     return {
  //       toasts: [
  //         ...toasts.toasts,
  //         {
  //           id: (toasts.toasts.length + 1).toString(),
  //           text,
  //           type
  //         }
  //       ],

  //     }
  //   })
  // }, [setToasts])

  return {
    createToast
  };
};


export default useToasts;
import { CommonActions, NavigationContext, useNavigation } from '@react-navigation/native';
import { AddressBookIcon } from 'assets/icons/AddressBook';
import { HomeIcon } from 'assets/icons/HomeIcon';
import { SettingsIcon } from 'assets/icons/SettingsIcon';
import { WalletIcon } from 'assets/icons/WalletIcon';
import { ThinText } from 'components/ui/text';
import { ERoute } from 'constants/routes';
import { COLORS } from 'constants/theme';
import React, { useCallback, useContext, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

interface IMenuProps {
    activeRoute: string
}

export const Menu: React.FC<IMenuProps> = ({ activeRoute }) => {
    const [ isActiveRoute, setIsActiveRoute ] = useState<ERoute>(ERoute.HOMEPAGE)
    const context = useContext(NavigationContext)
    const navigation = useNavigation()

    const handleNavigateToScreen = useCallback((route: ERoute) => {
        return () => {
            navigation.dispatch({...CommonActions.navigate(route)})
            setIsActiveRoute(route)
        }
    }, [navigation])

    return (
        <View style={styles.container}>
            <TouchableOpacity 
                onPress={handleNavigateToScreen(ERoute.HOMEPAGE)}
                style={styles.item}
            >
                <HomeIcon 
                    color={
                        (activeRoute === ERoute.HOMEPAGE
                        || activeRoute === ERoute.HOME_ASSETS
                        || activeRoute === ERoute.HOME_COLLECTIBLES)
                        && '#000'
                    }
                />
                <ThinText 
                    style={[
                        (activeRoute === ERoute.HOMEPAGE
                        || activeRoute === ERoute.HOME_ASSETS
                        || activeRoute === ERoute.HOME_COLLECTIBLES) 
                        ? {color: COLORS.BLACK} : {color: '#575E6A'}
                    ]}
                >
                    Home
                </ThinText>
            </TouchableOpacity>

            <TouchableOpacity
                onPress={handleNavigateToScreen(ERoute.WALLET)}
                style={styles.item}
            >
                <WalletIcon 
                    color={
                        (activeRoute === ERoute.WALLET
                        || activeRoute === ERoute.WALLET_ASSETS
                        || activeRoute === ERoute.WALLET_COLLECTIBLES
                        || activeRoute === ERoute.HISTORY) 
                        && '#000'
                    }
                />
                <ThinText 
                    style={[
                        (activeRoute === ERoute.WALLET
                        || activeRoute === ERoute.WALLET_ASSETS
                        || activeRoute === ERoute.WALLET_COLLECTIBLES
                        || activeRoute === ERoute.HISTORY) 
                        ? {color: COLORS.BLACK} : {color: '#575E6A'}
                    ]}
                >
                    Wallet
                </ThinText>
            </TouchableOpacity>

            <TouchableOpacity 
                onPress={handleNavigateToScreen(ERoute.ADDRESS_BOOK)}
                style={styles.item}
            >
                <AddressBookIcon color={activeRoute === ERoute.ADDRESS_BOOK && '#000'} />
                <ThinText style={[activeRoute === ERoute.ADDRESS_BOOK ? {color: COLORS.BLACK} : {color: '#575E6A'}]}>Address book</ThinText>
            </TouchableOpacity>

            <TouchableOpacity 
                onPress={handleNavigateToScreen(ERoute.SETTINGS)}
                style={styles.item}
            >
                <SettingsIcon color={activeRoute === ERoute.SETTINGS && '#000'} />
                <ThinText style={[activeRoute === ERoute.SETTINGS ? {color: COLORS.BLACK} : {color: '#575E6A'}]}>Settings</ThinText>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: 9999,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingRight: 24,
        paddingLeft: 24,
        paddingTop: 16,
        paddingBottom: 16,
        borderTopWidth: 1,
        borderTopColor: '#CDCCCF',
        backgroundColor: '#fff'
    },
    item: {
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4
    }
})
import { MagnifyingGlassIcon } from "assets/icons/MagnifyingGlassIcon";
import { BRADIUS, COLORS, PADDING } from "constants/theme";
import React, { useCallback, useState } from "react";
import { View, StyleSheet, TextInput, TextInputKeyPressEventData, NativeSyntheticEvent } from "react-native";

interface ISearchProps {
    placeholder: string
    value: string
    onChangeText: (text: string) => void
}

export const Search: React.FC<ISearchProps> = ({ placeholder, value, onChangeText }) => {
    return (
        <View style={styles.wrapper}>
            <MagnifyingGlassIcon style={{marginLeft: 24}} />

            <TextInput
                placeholder={placeholder}
                placeholderTextColor={COLORS.SUB_TEXT_DARK}
                value={value}
                onChangeText={onChangeText}
                style={styles.input}
                selectTextOnFocus
            />
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#6D6D6D',
        borderRadius: BRADIUS.MD,
        flexDirection: 'row',
        alignItems: 'center'
    },
    input: {
        padding: PADDING.MD,
        flex: 1,
        borderWidth: 0, 
        borderColor: 'none',
        outlineStyle: 'none'
    }
})
import { COLORS } from 'constants/theme';
import React from 'react';
import { ComponentProps } from 'react';
import { Svg, Path } from 'react-native-svg';

interface IAddressBookIconProps extends ComponentProps<'svg'> {
    color?: string 
}

export const AddressBookIcon: React.FC<IAddressBookIconProps> = ({ color, width, height }) => {
    return (
        <Svg 
            width={width || 25} 
            height={height || 24} 
            viewBox="0 0 25 24" 
            fill="none"
        >
            <Path 
                d="M13.0834 13.5C14.7402 13.5 16.0834 12.1569 16.0834 10.5C16.0834 8.84315 14.7402 7.5 13.0834 7.5C11.4265 7.5 10.0834 8.84315 10.0834 10.5C10.0834 12.1569 11.4265 13.5 13.0834 13.5Z" 
                stroke={color || "#575E6A"} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M3.33337 10.125H5.58337" 
                stroke={color || "#575E6A"} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M3.33337 6.375H5.58337" 
                stroke={color || "#575E6A"} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M3.33337 13.875H5.58337" 
                stroke={color || "#575E6A"} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M3.33337 17.625H5.58337" 
                stroke={color || "#575E6A"} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M8.58337 15.75C9.10733 15.0514 9.78674 14.4844 10.5678 14.0938C11.3489 13.7033 12.2101 13.5 13.0834 13.5C13.9566 13.5 14.8179 13.7033 15.5989 14.0938C16.38 14.4844 17.0594 15.0514 17.5834 15.75" 
                stroke={color || "#575E6A"} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M20.5834 20.25V3.75C20.5834 3.33579 20.2476 3 19.8334 3L6.33337 3C5.91916 3 5.58337 3.33579 5.58337 3.75L5.58337 20.25C5.58337 20.6642 5.91916 21 6.33337 21H19.8334C20.2476 21 20.5834 20.6642 20.5834 20.25Z" 
                stroke={color || "#575E6A"} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}

import {PublicKey} from "@solana/web3.js";
import * as anchor from '@project-serum/anchor';
import {BEARD_SPACE, STACHE, VAULT_SPACE} from "./program-utils";


// stachepda = beard pda
export const findStachePda = (name: string, domainPda: PublicKey, stacheprogid: PublicKey): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from(anchor.utils.bytes.utf8.encode(name)),
        Buffer.from(anchor.utils.bytes.utf8.encode(BEARD_SPACE)),
        domainPda.toBuffer(),
        Buffer.from(anchor.utils.bytes.utf8.encode(STACHE)),
      ],
      stacheprogid,
  );
};

export const findVaultPda = (vaultName: string, stacheid: string, domainPda: PublicKey, stacheprogid: PublicKey): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from(anchor.utils.bytes.utf8.encode(vaultName)),
        Buffer.from(anchor.utils.bytes.utf8.encode(VAULT_SPACE)),
        Buffer.from(anchor.utils.bytes.utf8.encode(stacheid)),
        Buffer.from(anchor.utils.bytes.utf8.encode(BEARD_SPACE)),
        domainPda.toBuffer(),
        Buffer.from(anchor.utils.bytes.utf8.encode(STACHE)),
      ],
      stacheprogid,
  );
};


// get the thread pda (clockwork) for the given thread id and authority (program that will get executed/owns the thread)
/*
export const findThreadPda = (id: string, threadAuthority: PublicKey): [PublicKey, number] => {
  return anchor.web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from(anchor.utils.bytes.utf8.encode('thread')),
        threadAuthority.toBuffer(),
        Buffer.from(anchor.utils.bytes.utf8.encode(id)),
      ],
      ThreadProgramId,
  );
}
 */



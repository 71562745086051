import { PencilIcon } from "assets/icons/PencilIcon";
import { Avatar } from "components/Avatar";
import { CustomInput } from "components/CustomInput";
import { MediumText, NormalText, SubHeaderText } from "components/ui/text";
import { BRADIUS, COLORS, PADDING, SPACING } from "constants/theme";
import React, { useCallback, useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

interface IWalletInfoProps {}

export const WalletInfo: React.FC<IWalletInfoProps> = () => {
    const initialWalletAddress = "0x3232s...111dxdx" // change this

    const [ areaType, setAreaType ] = useState("text")
    const [ walletAddressText, setWalletAddressText ] = useState(initialWalletAddress)
    const [ inputValue, setInputValue ] = useState({value: ''})


    const handleChangeAreaType = useCallback(() => {
        setAreaType("input")
    }, [setAreaType])

    const handleSubmitInputArea = useCallback(() => {
        setAreaType("text")

        setWalletAddressText(inputValue.value)
    }, [setWalletAddressText, setAreaType, inputValue])

    const handleChangeInput = useCallback((text: string) => {
        setInputValue(inputValue => {
            return {
                ...inputValue,
                value: text
            }
        })
    }, [setInputValue])

    return (
        <View style={{width: '100%', alignItems: 'center'}}>
            <Avatar style={styles.avatar} iconUrl="" />

            <View>
                <SubHeaderText style={{marginTop: 12}}>Labelname</SubHeaderText>
            </View>

            <View style={{alignItems: 'center', gap: SPACING.LG}}>
                <NormalText style={{color: '#111418', marginTop: 12}}>Trust Wallet</NormalText>

                <View style={areaType === "text" && styles.walletAddress}>
                    <TouchableOpacity onPress={handleChangeAreaType}>
                        {areaType === "input"
                            ? <CustomInput 
                                value={inputValue.value || initialWalletAddress} 
                                onChangeText={handleChangeInput} 
                                style={styles.walletAddress}
                                onBlur={handleSubmitInputArea}
                                defaultValue={walletAddressText || initialWalletAddress}
                            />
                            : <View style={{flexDirection: 'row', alignItems: 'center', gap: SPACING.MD}}>
                                <MediumText style={{color: '#6D6D6D'}}>
                                    {walletAddressText || initialWalletAddress}
                                </MediumText>
                                <PencilIcon />
                            </View>
                        }
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    avatar: {
        width: 96,
        height: 96,
    },
    walletAddress: {
        paddingLeft: PADDING.XL,
        paddingRight: PADDING.XL,
        paddingTop: PADDING.MD,
        paddingBottom: PADDING.MD,
        gap: SPACING.LG,
        borderWidth: 1,
        borderColor: COLORS.BLACK,
        borderRadius: BRADIUS.MD,
        flexDirection: 'row',
        width: 'fit-content'
    }
})
import {ScreenContainer} from "components/ui/container";
import {ERoute} from "constants/routes";
import React from "react";
import {useRecoilValue} from "recoil";
import {homepageAtom} from "../../../_state/screen-props";
import {Stash} from "../../../components/stash-transfers/Stash";
import {Unstash} from "../../../components/stash-transfers/Unstash";
import {Collectible, TokenBag} from "../../../_state/state-types";
import {appStateAtom} from "../../../_state/app-state";

interface IStashingProps {
  tokenBag?: TokenBag;
  collectible?: Collectible;
}

// @ts-ignore
export const Stashing: React.FC<IStashingProps> = () => {
  const homepageProps = useRecoilValue(homepageAtom);
  const appState = useRecoilValue(appStateAtom);

  return (
      <ScreenContainer style={{paddingTop: 8}}>
        {homepageProps.stache &&
            //  on stache wallet, so we unstash
           <Unstash
              tokenBag={appState.tokenBag}
              collectible={appState.collectible}
              from={'Stache Wallet'}
              to={'Connected Wallet'}
              tabs={[
                {
                  name: 'Connected Wallet',
                  route: ERoute.CONNECTED
                },
                {
                  name: 'Linked  Wallets',
                  route: ERoute.LINKED
                }
              ]}
           />
        }
        {!homepageProps.stache &&
            // on connected wallet, so we stash
           <Stash/>
        }
      </ScreenContainer>
  )
}

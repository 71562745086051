import {atom} from "recoil";
import {INotification} from "./state-types";


export const notificationsAtom = atom<INotification[]>({
  key: 'notifications',
  default: [
    {
      message: 'Your balance increased  by 12%',
      time: '11/12/22  03:12',
      action: 'Increment'
    },
    {
      message: 'A deposit of 0.2 SOL  was made into your stache wallet',
      time: '11/12/22  03:12',
      action: 'Stashed'
    },
    {
      message: 'You just stached 0.02 SOL into your stache wallet ',
      time: '11/12/22  03:12',
      action: 'Unstashed'
    },
  ]
})

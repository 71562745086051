import React from 'react';
import { ComponentProps, CSSProperties } from 'react';
import { Svg, Path } from 'react-native-svg';

interface IArrowIconProps extends ComponentProps<'svg'> {
    style?: Partial<Record<keyof CSSProperties, any>>
}

export const ArrowIcon: React.FC<IArrowIconProps> = ({ width, height, style={} }) => {
    return (
        <Svg 
            width={width || 34}
            height={height || 34} 
            viewBox="0 0 34 34" 
            fill="none"
            style={style}
        >
            <Path 
                d="M17.0002 25.4853V8.51472" 
                stroke="#101828" 
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M10.1057 15.4089L17 8.51464L23.8943 15.4089" 
                stroke="#101828" 
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}

import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ScreenContainer} from "components/ui/container";
import {ERoute} from "constants/routes";
import React from "react";
import {useRecoilValue} from "recoil";
import {Assets} from "screens/Wallet/Assets";
import {Collectibles} from "screens/Wallet/Collectibles";
import {stacheWalletAtom} from "../../../_state/stachewallet-state";
import {connectedWalletAtom} from "../../../_state/connectedwallet-state";

interface IWalletsProps {
  route: ERoute
}

export const StacheWallet: React.FC<IWalletsProps> = ({route}) => {
  const Tab = createNativeStackNavigator()
  const stacheWallet = useRecoilValue(stacheWalletAtom);

  return (
      <ScreenContainer style={{padding: 0}}>
        <Tab.Navigator initialRouteName={route}>
          <Tab.Screen
              name={route}
              options={{headerShown: false}}
          >
            {route === ERoute.HOME_ASSETS
                ? (props) => <Assets {...props} assets={stacheWallet?.tokens} wallet={stacheWallet}/>
                : (props) => <Collectibles {...props} collectibles={stacheWallet?.collectibles} wallet={stacheWallet}/>}
          </Tab.Screen>
        </Tab.Navigator>
      </ScreenContainer>
  )
}

export const ConnectedWallet: React.FC<IWalletsProps> = ({route}) => {
  const Tab = createNativeStackNavigator();
  const connectedWallet = useRecoilValue(connectedWalletAtom);

  return (
      <ScreenContainer style={{padding: 0}}>
        <Tab.Navigator initialRouteName={route}>
          <Tab.Screen
              name={route}
              options={{headerShown: false}}
          >
            {route === ERoute.HOME_ASSETS
                ? (props) => <Assets {...props} assets={connectedWallet?.tokens} wallet={connectedWallet}/>
                : (props) => <Collectibles {...props} collectibles={connectedWallet?.collectibles}
                                           wallet={connectedWallet}/>}
          </Tab.Screen>
        </Tab.Navigator>
      </ScreenContainer>
  )
}


import { CircleContainer } from "components/ui/container";
import { BannerText, NormalText, ThinText } from "components/ui/text";
import { BRADIUS, COLORS, PADDING, SPACING } from "constants/theme";
import React from "react";
import { View, StyleSheet } from "react-native";

interface IAssetCardProps {
    abbrev: string
    action: string
    value: number
    balance: string
    style?: any
}

export const AssetCard: React.FC<IAssetCardProps> = ({ abbrev, action, value, balance, style={} }) => {
    return (
        <View style={{...styles.wrapper, ...style}}>
            <View style={styles.container}>
                <View style={styles.tokenWrapper}>
                    <CircleContainer style={{width: 24, height: 24}}/>
                    <NormalText>{abbrev}</NormalText>
                </View>

                <View style={styles.info}>
                    <ThinText style={{color: '#9A9A9A'}}>You are {action}</ThinText>
                    <ThinText style={{color: '#9A9A9A'}}>{value}</ThinText>
                </View>
            </View>

            <BannerText style={{color: '#646464'}}>Balance: {balance} {abbrev}</BannerText>
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: COLORS.WHITE,
        alignItems: 'flex-end',
        gap: SPACING.MD
    },
    container: {
        width: '100%',
        borderRadius: BRADIUS.XL,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.05)',
        flexDirection: 'row',
        alignItems: 'center'
    },
    tokenWrapper: {
        padding: PADDING.XL,
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        width: 88,
        flexDirection: 'row',
        justifyContent: 'center',
        gap: SPACING.MD
    },
    info: {
        gap: SPACING.SM,
        paddingLeft: PADDING.XL,
        paddingRight: PADDING.XL,
        paddingTop: PADDING.MD,
        paddingBottom: PADDING.MD
    }
})
import {atom, selector} from 'recoil';
import jwt_decode from 'jwt-decode';
import {UserProfileState} from "./state-types";


export const userProfileAtom = atom<UserProfileState>({
  key: 'userProfile',
  default: {
    jwt: localStorage.getItem('jwt'),
  }
});

export const isValidToken = selector<Boolean>({
  key: 'validToken',
  get: async ({get}) => {
    const userProfileState: UserProfileState = get(userProfileAtom);
    if (userProfileState.jwt === null) return false;

    const decoded: any = jwt_decode(userProfileState.jwt);
    const expiration = decoded.exp * 1000; // gets returned in seconds, convert to millis for date comparisons
    if (decoded && 'exp' in decoded) {
      console.log("Dates? ", new Date(), new Date(expiration));
      return new Date() < new Date(expiration);
    } else return false;
  }
})

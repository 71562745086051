import {Collectible, IWallet, TokenBag} from "../_state/state-types";
import {appStateAtom} from "../_state/app-state";
import {useRecoilState} from "recoil";

function useAppActions() {

  const [appState, setAppState] = useRecoilState(appStateAtom);

  function setInteraction(wallet: IWallet, tokenBag: TokenBag, collectible: Collectible) {
    setAppState({
      ...appState,
      wallet,
      tokenBag,
      collectible
    });
  }

  return {
    setInteraction
  }

}


export {useAppActions}

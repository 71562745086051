import {HELIUS_API_KEY, HELIUS_RPC_URL} from "../config";
import axios from "axios";
import {Connection, PublicKey} from "@solana/web3.js";
import {TOKEN_PROGRAM_ID} from '@solana/spl-token';
import {Assets} from "./helius-types";
import {Collectible, NFT, TokenBag} from "../../_state/state-types";
import {STokenAccount} from "./solana-types";


class HeliusClient {

  constructor() {
  }

  async getMetadata(nftAddresses: string[]): Promise<any> {
    const totalNum = nftAddresses.length;
    let page = 1;
    let oldestTransaction = '';
    let transactions = [];

    while (transactions.length < totalNum) {
      const {data} = await axios({
        url: "https://api.helius.xyz/v0/tokens/metadata?api-key=" + HELIUS_API_KEY + `&before=${oldestTransaction}`,
        method: 'POST',
        data: {mintAccounts: nftAddresses},
      })

      if (data.length === 0) {
        return transactions; // Exhausted all transactions
      }

      // API data is already sorted in descending order
      oldestTransaction = data[data.length - 1].signature;
      transactions.push(...data);
      page += 1;
    }

    // console.log(`Got ${transactions.length} total transactions!`);
    return transactions;
  }

  async getBalances(walletAddress: PublicKey): Promise<any> {
    const {data} = await axios({
      url: `https://api.helius.xyz/v0/addresses/${walletAddress}/balances?api-key=` + HELIUS_API_KEY,
      method: 'GET'
    })

    return data
  }

//   decideIfPartOfCollection(nftData: any, collections: CollectionsState){
//     console.log("Deciding if part of collection: ", nftData, collections);

//     const creators = nftData.onChainData.data.creators;
//     const collectionProp = nftData.onChainData.collection;
//     let collection = null;
//     // New way of verify NFT is authentic part of collection - metaplex collections
//     if (collectionProp && collectionProp.verified) {
//         collection = collections.collections.find(collection => collection.verification === VERIFICATION.COLLECTION &&
//                                                                 collection.verificationAddress === collectionProp.key)
//     // Old way of verifying NFT is authentic part of collection
//     } else if (creators) {
//         const candyCreator = creators[0];
//         collection = collections.collections.find(collection => collection.verification === VERIFICATION.CREATOR &&
//                                                                 collection.verificationAddress === candyCreator.address)
//         if (!candyCreator.verified) {
//             // then unset it
//             collection = null;
//         }
//     }
//     return collection ? collection.name : null;
//   }

  async getNFTsForOwner(
      owner: PublicKey,
      // collections: CollectionsState,
      // favorites: { mint: PublicKey }[]
  ): Promise<NFT[]> {

    const conn = new Connection(HELIUS_RPC_URL);

    const tokenAccounts = await conn.getParsedTokenAccountsByOwner(owner, {
      programId: TOKEN_PROGRAM_ID,
    });

    const parsedTokens = tokenAccounts.value
        .filter((t: any) => {
          const amount = t.account.data.parsed.info.tokenAmount;
          return amount.decimals === 0 && amount.uiAmount === 1;
        })
        .map((t: any) => {
          return {pubkey: t.pubkey, mint: t.account.data.parsed.info.mint};
        });

    const getTokenMetaDatas = async (tokenAddresses: string[]) => {

      return await this.getMetadata(tokenAddresses)
          .then(res => {
            ////// Section below used for marking NFT collections as favorites and whether part of a collection

            // Maps and filters nfts simultaneously
            // const allNFTS: NFT [] = res.reduce((array: NFT[], nft: any) => {
            //     if(nft && nft.onChainData && nft.offChainData){
            //         const parsedNFT = {
            //             owner: owner,
            //             name: nft.onChainData.data.name,
            //             mint: new PublicKey(nft.mint),
            //             imageUrl: nft.offChainData.image,
            //             collection: this.decideIfPartOfCollection(nft, collections),
            //             isFavorited: favorites.map(fav => fav.mint.toBase58()).includes(nft.mint)
            //         }
            //         array.push(parsedNFT);
            //     }
            //     return array;
            // }, []);
            return res;
          })
          .catch(e => {
            console.log('error getting nft data: ', e);
            return [];
          });
    }

    return await getTokenMetaDatas(parsedTokens.map(tokenAccount => tokenAccount.mint));
  }

  // fetches the token data for the given token accounts and parses into collectibles and tokens
  // todo: hit the helius token metadata api (mainnet only)
  async fetchAssetMetaData(tokenAccounts: STokenAccount[]): Promise<Assets> {
    // separate into collectibles and tokens
    const collectibles: Collectible[] = [];
    const tokens: TokenBag[] = [];

    for (const tokenAccount of tokenAccounts) {
      if (tokenAccount.amount == 1) {
        // then assume it's an nft
        collectibles.push({
          mint: new PublicKey(tokenAccount.mint),
          name: 'Shadowy Super Coder #7137',
          // todo: connect to helius to get the image/collection data, for now just use static data
          imageUrl: 'https://shdw-drive.genesysgo.net/8yHTE5Cz3hwcTdghynB2jgLuvKyRgKEz2n5XvSiXQabG/7137.png',
          collection: 'SSC',
          tokenAccount: tokenAccount.address
        });
      } else {
        tokens.push({
          mint: new PublicKey(tokenAccount.mint),
          amount: tokenAccount.amount,
          amountUi: tokenAccount.amountUi,
          amountUiString: tokenAccount.amountUiString,
          decimals: tokenAccount.decimals,
          // todo: lookup name and symbol based off mint
          name: tokenAccount.mint.toString(),
          symbol: "TOKEN",
          tokenAccount: tokenAccount.address
        })
      }
    }
    return {
      tokens,
      collectibles
    }
  }


}

export default new HeliusClient();

import React from "react";
import { ComponentProps } from "react";
import Svg, { Path } from "react-native-svg";

interface ISettingsIconProps extends ComponentProps<'svg'> {
    color?: string
}

export const SettingsIcon: React.FC<ISettingsIconProps> = ({ color, width, height }) => {
    return (
        <Svg 
            width={width || 21} 
            height={height || 21} 
            viewBox="0 0 24 22" 
            fill='none'
        >
            <Path 
                d="M12.5 16.5C14.9853 16.5 17 14.4853 17 12C17 9.51472 14.9853 7.5 12.5 7.5C10.0147 7.5 8 9.51472 8 12C8 14.4853 10.0147 16.5 12.5 16.5Z" 
                stroke={color || "#575E6A"} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M17.7218 6.10303C17.9593 6.32178 18.1843 6.54678 18.3968 6.77803L20.9562 7.14365C21.3732 7.86788 21.6947 8.64308 21.9125 9.4499L20.3562 11.5218C20.3562 11.5218 20.3843 12.1593 20.3562 12.478L21.9125 14.5499C21.6957 15.3571 21.3742 16.1324 20.9562 16.8562L18.3968 17.2218C18.3968 17.2218 17.9562 17.6812 17.7218 17.8968L17.3562 20.4562C16.632 20.8732 15.8568 21.1946 15.05 21.4124L12.9781 19.8562C12.66 19.8843 12.34 19.8843 12.0218 19.8562L9.94996 21.4124C9.1428 21.1956 8.36747 20.8741 7.64371 20.4562L7.27809 17.8968C7.04684 17.678 6.82184 17.453 6.60309 17.2218L4.04371 16.8562C3.6267 16.1319 3.30527 15.3567 3.08746 14.5499L4.64371 12.478C4.64371 12.478 4.61559 11.8405 4.64371 11.5218L3.08746 9.4499C3.30426 8.64274 3.62574 7.8674 4.04371 7.14365L6.60309 6.77803C6.82184 6.54678 7.04684 6.32178 7.27809 6.10303L7.64371 3.54365C8.36794 3.12664 9.14314 2.80521 9.94996 2.5874L12.0218 4.14365C12.34 4.11552 12.66 4.11552 12.9781 4.14365L15.05 2.5874C15.8571 2.8042 16.6325 3.12568 17.3562 3.54365L17.7218 6.10303Z" 
                stroke={color || "#575E6A"} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}



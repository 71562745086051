//Components
import { View } from "react-native";
import { Menu } from "components/Menu";
import { ERoute } from "constants/routes";

//Data
import { RecoilRoot } from "recoil";

//Libs
import { registerRootComponent } from "expo";
import { NavigationContainer, NavigationContainerRefWithCurrent, useNavigationContainerRef } from "@react-navigation/native";
import { ProviderComponentWrapper } from "components/ProviderComponentWrapper";
import { createNativeStackNavigator } from '@react-navigation/native-stack';

//Styles
import 'assets/css/solana-wallet-adapter-react-ui-styles.css';
import * as Theme from 'constants/theme';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { AppRoutes } from "./AppRoutes";
import React from "react";

function App() {
  const [ showMenu, setShowMenu ] = useState(false)
  const [ activeRoute, setActiveRoute ] = useState('')

  const navigationRef = useNavigationContainerRef()
  const routeNameRef = useRef('')

  // This will be removed when login/register screens are implemented
  useEffect(() => {
    if (routeNameRef?.current === ERoute.HOMEPAGE) {
      setShowMenu(true)
    }
  }, [routeNameRef, setShowMenu])

  const handleOnReadyNavigation = useCallback(() => {
    routeNameRef.current = navigationRef.getCurrentRoute().name
  }, [routeNameRef.current, navigationRef])

  return (
    <RecoilRoot>
      <NavigationContainer 
        ref={navigationRef} 
        onReady={handleOnReadyNavigation}
        onStateChange={async () => {
          const previousRouteName = routeNameRef.current
          const currentRouteName = navigationRef.getCurrentRoute().name
          setActiveRoute(currentRouteName)
          const trackScreenView = (currentRouteName: string) => {
            if (currentRouteName === ERoute.HOMEPAGE
                || currentRouteName === ERoute.SETTINGS
                || currentRouteName === ERoute.WALLET_ASSETS
                || currentRouteName === ERoute.HOME_ASSETS
                || currentRouteName === ERoute.WALLET_COLLECTIBLES
                || currentRouteName === ERoute.HOME_COLLECTIBLES
                || currentRouteName === ERoute.HISTORY
                || currentRouteName === ERoute.ADDRESS_BOOK
            ) {
              setShowMenu(true)
            }
            else {
              setShowMenu(false)
            }
          } 
  
          if (previousRouteName !== currentRouteName) {
            routeNameRef.current = currentRouteName

            await trackScreenView(currentRouteName)
          }
        }}
      >
        <View style={{height: '100vh'}}>
          <ProviderComponentWrapper
            children={
              <AppRoutes /> 
            } 
          />
          {showMenu && <Menu activeRoute={activeRoute} />}
        </View>
      </NavigationContainer>
    </RecoilRoot>
  );
}

export default registerRootComponent(App);
import { Button } from 'components/Button';
import React, { CSSProperties } from 'react';
import { StyleSheet, View, Modal } from "react-native";

interface ICustomModalProps {
    children: React.ReactNode
    isOpen: boolean
    close: () => void
    style?: Partial<Record<keyof CSSProperties, any>>
}

export const CustomModal: React.FC<ICustomModalProps> = ({ children, isOpen, close, style={} }) => {
    return (
        <Modal
            visible={isOpen}
            onRequestClose={close}
            presentationStyle="overFullScreen"
            transparent
        >
            <View style={styles.wrapper}>
                <View style={{...styles.container, ...style}}>
                    {children}
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
    },
    container: {
        backgroundColor: "#fff",
        width: '85%',
        minHeight: 296,
        borderRadius: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20
    }
})
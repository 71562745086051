import {atom} from 'recoil';
import {AppState} from "./state-types";

// for keeping track of what the user is doing
export const appStateAtom = atom<AppState | null>({
  key: 'stache',
  default: {
    tokenBag: null,
    collectible: null,
    wallet: null
  },
});

import { CommonActions, useNavigation } from '@react-navigation/native';
import { SearchBook } from 'components/SearchBook';
import { WhiteButton } from 'components/ui/button';
import { ScreenContainer } from 'components/ui/container';
import { ERoute } from 'constants/routes';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

interface ISearchAddressProps {}

export const SearchAddress: React.FC<ISearchAddressProps> = () => {
    const [ searchTerm, setSearchTerm ] = useState('')
    const navigation = useNavigation()
    
    const handleNavigateToScreen = useCallback((route: ERoute) => {
        return () => navigation.dispatch({...CommonActions.navigate(route)})
    }, [navigation])
    
    return (
        <ScreenContainer style={{gap: 56}}>
            <SearchBook 
                value={searchTerm} 
                onChangeText={setSearchTerm} 
                onPress={handleNavigateToScreen(ERoute.SENDING)} 
            />
            <View style={{width: '100%', alignItems: 'center'}}>
                <WhiteButton label='Add new user' onPress={handleNavigateToScreen(ERoute.ADD_USER)} icon />
            </View>
        </ScreenContainer>
    )
}
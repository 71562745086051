import {Button} from 'components/Button';
import {CustomInput} from 'components/CustomInput';
import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {useRecoilValue} from "recoil";
import {walletAtom} from "../../_state/wallet-state";
import {consoleLog} from "../../_helpers/debug";
import {BigHeaderText, MediumText, NormalText} from 'components/ui/text';
import {useStacheActions} from "../../_actions/stache-actions";

interface IVerificationProps {
  title: string
  value: string
  hint: string
  buttonLabel: string
  handleButtonNavigate: () => void
  text?: string
  link?: string
  handleNavigate?: () => void,
  creation: boolean
}

export const Verification: React.FC<IVerificationProps> = ({
                                                             title,
                                                             value,
                                                             hint,
                                                             buttonLabel,
                                                             handleButtonNavigate,
                                                             text,
                                                             link,
                                                             handleNavigate,
                                                             creation
                                                           }) => {

  const wallet = useRecoilValue(walletAtom);
  const stacheActions = useStacheActions();
  const [stacheid, setStacheid] = React.useState('');

  // todo: add an information tooltip to explain what a stache is

  async function handleSubmit() {
    consoleLog('submitting stache id');
    // todo: change button label to creating or spinner or something and disable
    if (creation) {
      // todo: 32 character limit for stacheid
      consoleLog(`creating new stache for ${stacheid}`);

      // todo: loader modal..?

      const stache = await stacheActions.createStache(stacheid, wallet.address);
      if (stache) {

        // todo: display modal w/login button and some text indicating smart wallet has been created (your stache)
        // todo: will also need some text indicating how to get to help screen and why (new terminology, concepts, etc...)
        // login button should kick off the login sequence, then land them on the home screen
      }


    } else {
      consoleLog('not handling verification yet');
    }
  }

  return (
      <View style={styles.wrapper}>
        <View>
          <BigHeaderText style={{fontSize: 36, lineHeight: 42}}>{title}</BigHeaderText>
          {text &&
             <NormalText style={{color: '#001533', marginTop: 4}}>{text}</NormalText>}
        </View>

        <View>
          <CustomInput
              label='Stache ID'
              value={value}
              placeholder='stacheman.sol'
              onChangeText={setStacheid}
              styleLabel={styles.label}
          />
          <MediumText style={[{color: '#667085'}, link && styles.textWithLink]}>
            {hint}
            {link &&
               <TouchableOpacity onPress={handleNavigate}>
                  <MediumText style={{color: '#667085'}}>{link}</MediumText>
               </TouchableOpacity>
            }
          </MediumText>
        </View>

        <View>
          <CustomInput
              label='First Linked Wallet (connected wallet)'
              value={value}
              placeholder={wallet?.address.toBase58()}
              onChangeText={() => null}
              styleLabel={styles.label}
          />
        </View>

        <Button
            label={buttonLabel}
            onPress={handleSubmit}
            styleContainer={styles.button}
            styleLabel={styles.buttonLabel}
        />
      </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    gap: 32
  },
  title: {
    fontSize: 36,
    lineHeight: 42,
    color: '#030E20'
  },
  textWithLink: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2
  },
  label: {
    fontSize: 14,
    lineHeight: 20,
    color: '#1B1C1D',
  },
  button: {
    width: '100%',
    backgroundColor: '#090909',
    marginTop: 64,
    padding: 12,
  },
  buttonLabel: {
    fontSize: 18,
    lineHeight: 21,
    color: '#FAFAFA'
  }
})

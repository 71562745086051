import {BackIcon} from 'assets/icons/BackIcon';
import {BannerText} from 'components/ui/text';
import {EOption, IDropdownOption} from 'constants/dropdown';
import {BRADIUS, COLORS, PADDING, SPACING} from 'constants/theme';
import {uniqueId} from 'lodash';
import React, {CSSProperties, useCallback, useState} from 'react';
import {LayoutRectangle, StyleSheet, TouchableOpacity, View} from 'react-native';
import {EType} from "../../_state/state-types";

interface ICustomDropdownProps {
  options: IDropdownOption[]
  value: EOption | EType
  onChange: (value: EOption | EType) => void
  style?: Partial<Record<keyof CSSProperties, any>>
}

export const CustomDropdown: React.FC<ICustomDropdownProps> = ({options, value, onChange, style = {}}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [dropdownValue, setDropdownValue] = useState(value)
  const [height, setHeight] = useState(0)

  const handleToggleDropdown = useCallback(() => {
    if (options.length < 2) {
      return
    }

    setIsDropdownOpen(isDropdownOpen => !isDropdownOpen)
  }, [setIsDropdownOpen])

  const handleChooseOption = useCallback((e: any) => {
    setDropdownValue(e.target.textContent)
    onChange(e.target.textContent)

    setIsDropdownOpen(false)
  }, [setDropdownValue])

  const findCurrentHeight = useCallback((layout: LayoutRectangle) => {
    const {height} = layout;

    setHeight(height)
  }, [setHeight])

  return (
      <View style={{...styles.wrapper, ...style}}>
        <TouchableOpacity
            style={[styles.dropdown, isDropdownOpen && styles.dropdownOpen]}
            onPress={handleToggleDropdown}
            onLayout={(event) => {
              findCurrentHeight(event.nativeEvent.layout)
            }}
        >
          <BannerText>{dropdownValue}</BannerText>
          <BackIcon
              width={8}
              height={11}
              style={{
                marginTop: 2,
                transform: isDropdownOpen ? 'rotate(90deg)' : 'rotate(270deg)'
              }}
          />
        </TouchableOpacity>

        {isDropdownOpen &&
           <View
              style={{
                ...styles.menu,
                ...styles.menuOpen,
                top: isDropdownOpen && height - 2
              }}
           >
             {options.map(option => {
               if (option.name === dropdownValue) {
                 return
               }

               return (
                   <TouchableOpacity
                       key={uniqueId()}
                       style={styles.option}
                       onPress={handleChooseOption}
                   >
                     <BannerText>{option.name}</BannerText>
                   </TouchableOpacity>
               )
             })}
           </View>
        }
      </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    zIndex: 1111,
  },
  dropdown: {
    borderRadius: BRADIUS.MD,
    borderWidth: 1,
    borderColor: COLORS.BLACK,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: SPACING.MD,
    padding: PADDING.MD
  },
  dropdownOpen: {
    borderBottomLeftRadius: BRADIUS.NONE,
    borderBottomRightRadius: BRADIUS.NONE,
    borderBottomWidth: 0
  },
  menu: {
    width: '100%',
    borderRadius: BRADIUS.MD,
    borderWidth: 1,
    borderColor: COLORS.BLACK,
    position: 'absolute',
    backgroundColor: COLORS.WHITE,
    opacity: 1,
    zIndex: 1111
  },
  menuOpen: {
    borderTopLeftRadius: BRADIUS.NONE,
    borderTopRightRadius: BRADIUS.NONE,
    borderTopWidth: 0
  },
  option: {
    padding: PADDING.MD,
  }
})

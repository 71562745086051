import { COLORS } from "constants/theme";
import React from "react";
import { View, StyleSheet } from "react-native";

interface ILinkedProps {}

export const Linked: React.FC<ILinkedProps> = () => {
    return (
        <View style={styles.wrapper}></View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        backgroundColor: COLORS.WHITE,
    }
})
import {atom} from "recoil";
import {IHome} from "./state-types";

export const homepageAtom = atom<IHome>({
  key: 'homepage',
  default: {
    stache: true,
    asset: true
  }
})

export interface IFaq {
  title: string
  text: string
}

export const faqAtom = atom<IFaq>({
  key: 'faq',
  default: {
    title: '',
    text: ''
  }
})

import {useRecoilState, useRecoilValue} from "recoil";
import {consoleLog} from "../_helpers/debug";
import heliusclient from "../constants/apis/heliusclient";
import {Solana, TokenBag, WalletType} from "../_state/state-types";
import {solanaAtom} from "../_state/solana";
import {STokenAccount} from "../constants/apis/solana-types";
import {walletAtom} from "../_state/wallet-state";
import {connectedWalletAtom} from "../_state/connectedwallet-state";

function useConnectedWalletActions() {
  const solana: Solana = useRecoilValue(solanaAtom);
  const [connectedWallet, setConnectedWallet] = useRecoilState(connectedWalletAtom);
  const wallet = useRecoilValue(walletAtom);

  // load the stache wallet info
  async function load(force: boolean = false) {
    // fetch all the token accounts
    // await stacheWalletLoader();
    if (!connectedWallet || force) {
      consoleLog('connected wallet not found, loading...');
      const solanaClient = solana.solanaClient;
      const tokenAccounts: STokenAccount[] = await solanaClient.getTokenAccounts(wallet.address);

      // consoleLog(`got token accounts for ${wallet.address.toString()}: ${JSON.stringify(tokenAccounts, null, 2)}`);
      const assets = await heliusclient.fetchAssetMetaData(tokenAccounts);
      const solAccount = await solanaClient.getSolTokenAccount(wallet.address);
      const solBag: TokenBag = {
        name: "Solana",
        symbol: "SOL",
        mint: wallet.address,
        tokenAccount: wallet.address,
        ...solAccount
      };

      // make SOL the first tokenbag
      assets.tokens.unshift(solBag);

      const label = connectedWallet?.label || wallet.address.toString();
      setConnectedWallet({
        label,
        walletType: WalletType.CONNECTED,
        address: wallet.address,
        collectibles: assets.collectibles,
        tokens: assets.tokens
      });
    }
  }

  return {
    load
  }
}

export {useConnectedWalletActions}

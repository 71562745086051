import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {Tabs} from 'components/Tabs';
import {TabsHeader} from 'components/TabsHeader';
import {BalanceContainer, ScreenContainer} from 'components/ui/container';
import {BigHeaderText, ThinText} from 'components/ui/text';
import {EOption} from 'constants/dropdown';
import {ERoute} from 'constants/routes';
import React, {useCallback, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {Assets} from 'screens/Wallet/Assets';
import {Collectibles} from 'screens/Wallet/Collectibles';
import {History} from 'screens/Wallet/History';
import {connectedKeySelector, keychainAtom} from '../../_state/keychain-state';
import {EType} from '../../_state/state-types';
import {useRecoilValue} from "recoil";

interface IWalletProps {
}

export const Wallet: React.FC<IWalletProps> = () => {
  const Tab = createNativeStackNavigator();
  const keychain = useRecoilValue(keychainAtom);
  const connectedKey = useRecoilValue(connectedKeySelector);

  const [dropdownValue, setDropdownValue] = useState(connectedKey);

  const handleChangeDropdownValue = useCallback((value: EOption | EType | any) => {
    setDropdownValue(value)
  }, [setDropdownValue])

  return (
      <ScreenContainer style={{paddingBottom: 96}}>
        <ScrollView>
          <BalanceContainer style={{paddingTop: 26, paddingBottom: 40}}>
            <ThinText style={{color: '#CFDAEC'}}>{dropdownValue.label}</ThinText>
            <BigHeaderText style={{color: '#F9F8F8'}}>$11,300.42</BigHeaderText>
          </BalanceContainer>

          <TabsHeader
              title='My Stache Wallet'
              value={dropdownValue.label}
              onChange={handleChangeDropdownValue}
              options={keychain.keys.map(key => {
                return {name: key.label}
              })}
              style={{marginTop: 50}}
          />

          <View style={{marginTop: 14, zIndex: -1}}>
            <Tabs
                tabs={[
                  {
                    name: 'Assets',
                    route: ERoute.WALLET_ASSETS
                  },
                  {
                    name: 'Collectibles',
                    route: ERoute.WALLET_COLLECTIBLES
                  },
                  {
                    name: 'History',
                    route: ERoute.HISTORY
                  }
                ]}
            />

            <Tab.Navigator initialRouteName={ERoute.WALLET_ASSETS}>
              <Tab.Screen name={ERoute.WALLET_ASSETS} component={Assets} options={{headerShown: false}}/>
              <Tab.Screen name={ERoute.WALLET_COLLECTIBLES} component={Collectibles}
                          options={{headerShown: false}}/>
              <Tab.Screen name={ERoute.HISTORY} component={History} options={{headerShown: false}}/>
            </Tab.Navigator>
          </View>
        </ScrollView>
      </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  balance: {
    fontSize: 24,
    color: '#F9F8F8'
  },
  walletActions: {
    paddingTop: 12
  }
})

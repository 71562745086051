import {CommonActions, useNavigation} from "@react-navigation/native";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ArrowIcon} from "assets/icons/ArrowIcon";
import {WalletConnectedModal} from "components/Modals";
import {ITab, Tabs} from "components/Tabs";
import {BlackButton} from "components/ui/button";
import {CircleContainer} from "components/ui/container";
import {NormalText, ThinText} from "components/ui/text";
import {ERoute} from "constants/routes";
import {BRADIUS, COLORS, PADDING, SPACING} from "constants/theme";
import React, {useCallback, useState} from "react";
import {StyleSheet, TouchableOpacity, View} from "react-native";
import {AssetCard} from "../common/AssetCard";
import {Connected} from "../common/Connected";
import {Linked} from "../common/Linked";
import {CollectibleCard} from "../common/CollectibleCard";
import {Collectible, TokenBag} from "../../../_state/state-types";
import {useRecoilValue} from "recoil";
import {NOTI_STATUS} from "../../../_state/toasts";
import {consoleLog} from "../../../_helpers/debug";
import {useConnectedWalletActions} from "../../../_actions/connectedwallet-actions";
import {useStacheWalletActions} from "../../../_actions/stachewallet-actions";
import {stacheWalletAtom} from "../../../_state/stachewallet-state";
import {connectedWalletAtom} from "../../../_state/connectedwallet-state";
import useToasts from "../../../hooks/useToasts";
import {appStateAtom} from "../../../_state/app-state";

interface IStashProps {
  from: string
  to: string,
  tabs: ITab[],
  tokenBag?: TokenBag;
  collectible?: Collectible;
}

export const Unstash: React.FC<IStashProps> = ({from, to, tabs}) => {
  const Tab = createNativeStackNavigator()
  const [openModal, setOpenModal] = useState(false)
  const appState = useRecoilValue(appStateAtom);
  const stacheWalletActions = useStacheWalletActions();
  const connectedWalletActions = useConnectedWalletActions();
  const stacheWallet = useRecoilValue(stacheWalletAtom);
  const connectedWallet = useRecoilValue(connectedWalletAtom);
  const {createToast} = useToasts();

  const navigation = useNavigation()

  const submitTransaction = useCallback(async () => {
    // unstash logic
    if (appState.tokenBag) {
      // let's unstash these guys

      // todo: this will come from input - for now just divide by 2
      const amount = Math.round(appState.tokenBag.amountUi / 2);

      try {
        await stacheWalletActions.unstashTokens(stacheWallet, connectedWallet, appState.tokenBag, amount);
        createToast(`Unstashed ${amount} tokens!`, NOTI_STATUS.SUCCESS);

        // now reload the stache wallet + connected
        stacheWalletActions.load(true);
        connectedWalletActions.load(true);

        setOpenModal(true);
      } catch (err) {
        createToast(`Error unstashing tokens: ${err}`, NOTI_STATUS.ERR);
      }
    } else if (appState.collectible) {
      // nfts baby
      // todo: implement
    } else {
      consoleLog('no tokenBag or collectible found in appState. don\'t know what to stash');
    }
  }, [setOpenModal])

  const handleNavigateToScreen = useCallback(() => {
    navigation.dispatch({...CommonActions.navigate(ERoute.HOMEPAGE)})
    setOpenModal(false)
  }, [setOpenModal, navigation])

  return (
      <View style={styles.wrapper}>
        <NormalText>From {from}</NormalText>

        {appState.tokenBag &&
           <AssetCard
              abbrev={appState.tokenBag.symbol}
              action='unstashing'
              value={appState.tokenBag.amountUi}
              balance={appState.tokenBag.amountUiString}
              style={{marginTop: 8}}
           />
        }
        {appState.collectible &&
           <CollectibleCard
              action='unstashing'
              name={appState.collectible.name}
              price='~$70'
              value='7.7 sol'
              style={{marginTop: 16}}
           />
        }

        <View style={{width: '100%', alignItems: 'center', marginTop: 24}}>
          <TouchableOpacity style={styles.arrow}>
            <ArrowIcon style={{transform: 'rotate(180deg)'}}/>
          </TouchableOpacity>
        </View>

        <Tabs
            tabs={tabs}
            style={{marginTop: 24}}
        />

        <Tab.Navigator initialRouteName={ERoute.CONNECTED}>
          <Tab.Screen
              name={ERoute.CONNECTED}
              options={{headerShown: false}}
          >
            {(props) =>
                <Connected
                    {...props}
                    to={to}
                    action='receiving'
                    tokenBag={appState.tokenBag}
                    collectible={appState.collectible}
                />
            }
          </Tab.Screen>
          <Tab.Screen
              name={ERoute.LINKED}
              component={Linked}
              options={{headerShown: false}}
          />
        </Tab.Navigator>

        <View style={styles.info}>
          <View style={styles.infoRow}>
            <View style={{flexDirection: 'row', gap: SPACING.MD}}>
              <ThinText>Exchange rate</ThinText>
              <CircleContainer style={styles.circle}/>
            </View>
            <ThinText>1 SOL = 100$</ThinText>
          </View>

          <View style={styles.infoRow}>
            <View style={{flexDirection: 'row', gap: SPACING.MD}}>
              <ThinText>Transaction Fee</ThinText>
              <CircleContainer style={styles.circle}/>
            </View>
            <ThinText>Free</ThinText>
          </View>

          {/*
          <View style={styles.infoRow}>
            <View style={{flexDirection: 'row', gap: SPACING.MD}}>
              <ThinText>On-Chain Fee</ThinText>
              <CircleContainer style={styles.circle}/>
            </View>
            <ThinText>0.6%</ThinText>
          </View>

          <View style={styles.infoRow}>
            <View style={{flexDirection: 'row', gap: SPACING.MD}}>
              <ThinText>Estimated Time of Arrival</ThinText>
              <CircleContainer style={styles.circle}/>
            </View>
            <ThinText>10-30mins</ThinText>
          </View>
*/}

        </View>

        <View style={{width: '100%', alignItems: 'center'}}>
          <BlackButton label='Continue' onPress={submitTransaction} style={{marginBottom: 32}} icon/>
        </View>

        <WalletConnectedModal
            isOpen={openModal}
            text='Success!'
            buttonLabel='Ok'
            handleNavigate={handleNavigateToScreen}
        />
      </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: COLORS.WHITE
  },
  arrow: {
    width: 40,
    height: 40,
    backgroundColor: '#F9F8F8',
    borderRadius: BRADIUS.ROUND,
    alignItems: 'center',
    justifyContent: 'center'
  },
  info: {
    padding: PADDING.XL,
    gap: SPACING.LG,
    backgroundColor: 'rgba(225, 225, 225, 0.24)',
    marginBottom: 64
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  circle: {
    width: 16,
    height: 16
  }
})

function useNavigate() {
  throw new Error("Function not implemented.");
}

import { CommonActions, useNavigation } from "@react-navigation/native";
import { Search } from "components/Search";
import { ScreenContainer } from "components/ui/container";
import { SubHeaderText } from "components/ui/text";
import { WalletAddressCard } from "components/WalletAddressCard";
import { ERoute } from "constants/routes";
import React, { useCallback, useState } from "react";
import { View, StyleSheet } from "react-native";

interface IAddUserProps {}

export const AddUser: React.FC<IAddUserProps> = () => {
    const [ searchTerm, setSearchTerm ] = useState('')
    const navigation = useNavigation()

    const result = true

    const handleNavigateToRoute = useCallback(() => {
        navigation.dispatch({...CommonActions.navigate(ERoute.NEW_USER)})
    }, [navigation])
    

    return (
        <ScreenContainer>
            <SubHeaderText style={{...styles.text, marginTop: 80}}>
                Enter Wallet address or Stache userid to add user to your address book 
            </SubHeaderText>

            <Search 
                placeholder="enter wallet address/stache userid here" 
                value={searchTerm} 
                onChangeText={setSearchTerm} 
            />

            {result
                ? <View>
                    <SubHeaderText style={styles.text}>Stache account result</SubHeaderText>
                    <WalletAddressCard name="@Stache username...." onPress={handleNavigateToRoute} />
                    {/* here will come wallet or stache result */}
                </View>
                : <SubHeaderText style={styles.text}>
                    Wallet address not found  or not valid! Kindly try again
                </SubHeaderText>
            }
        </ScreenContainer>
    )
}

const styles = StyleSheet.create({
    text: {
        color: '#4B4B4B',
        marginTop: 50,
        marginBottom: 16
    }
})
import {EType} from "../_state/state-types";

export enum EOption {
  MY_ASSETS = 'tokens',
  MY_COLLECTIBLES = 'collectibles',
  METAMASK_1 = 'phantom1',
  METAMASK_2 = 'phantom2',
  METAMASK_3 = 'phantom3'
}

export interface IDropdownOption {
  name: EOption | EType
}

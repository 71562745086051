import { COLORS } from 'constants/theme';
import React from 'react';
import { ComponentProps, CSSProperties } from 'react';
import { Svg, Path } from 'react-native-svg';

interface IBackIconProps extends ComponentProps<'svg'> {
    color?: string
    style?: Partial<Record<keyof CSSProperties, any>>
}

export const BackIcon: React.FC<IBackIconProps> = ({ color, width, height, style={} }) => {
    return (
        <Svg 
            width={width || 8} 
            height={height || 14}
            viewBox="0 0 8 14" 
            fill="none"
            style={style}
        >
            <Path 
                d="M7 13L1 7L7 1" 
                stroke={color || COLORS.BLACK} 
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}


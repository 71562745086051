import {PublicKey} from "@solana/web3.js";
import {SolanaClient} from "../constants/apis/solanaclient";

export interface IContact {
  name: string
  walletAddress: PublicKey
  stacheAddress?: PublicKey
  stacheName?: string
}

export interface IAddressBook {
  contacts: IContact[]
}

export interface IStache {
  // username & avatarUrl types not stated
  stacheid: any;
  avatarUrl: any;
  pda: PublicKey;
}

export enum EStatus {
  ADDED = 'ADDED',
  LINKED = 'LINKED'
}

export interface NFT {
  owner: PublicKey,
  name: string,
  mint: PublicKey,
  imageUrl: string,
  collection: string | null,
  isFavorited: boolean
}

export interface IKey {
  label: string;
  walletAddress: PublicKey;
  pda: PublicKey;
  status: EStatus.ADDED | EStatus.LINKED;
  verified: boolean;
}

export interface IKeychain {
  // string type for account address is only for test purpose
  pda: PublicKey | null;
  // name type not stated
  name: any;
  keys: IKey[];
  // whether the connected wallet is linked or not (verified)
  walletLinked: boolean;
}

export interface IHome {
  stache: boolean
  asset: boolean
}

export interface INotification {
  message: string
  time: string
  action: string
}

export interface INotifications {
  notifications: INotification[]
}

export interface Solana {
  solanaClient: SolanaClient
}

export interface UserProfileState {
  jwt: string | null
}

export interface ITokenBalance {
  // iconUrl, name & abbrev type not stated
  iconUrl: any
  name: any
  abbrev: any
  amount: number
  value: number
}

export enum EType {
  CONNECTED = 'CONNECTED',
  LINKED = 'LINKED',
  STACHE = 'STACHE',
  PENDING = 'PENDING'
}

export interface INft {
  owner: PublicKey
  name: string
  mint: PublicKey
  imgUrl: string
  mdUrl: string
  collection: string | null
  price?: string
  value?: string
}

export interface ConnectedWalletInfo {
  address: PublicKey
  key?: IKey | null
  type?: EType
  nfts?: INft[]
  tokens?: ITokenBalance[]
  checkedForStache: boolean,

  // whether the connected wallet is linked or not (verified) (same as the IKeychain.walletLinked; here as convenience)
  walletLinked: boolean
}

export interface Collectible {
  mint: PublicKey;
  name: string;
  imageUrl: string,
  collection: string | null,
  tokenAccount: PublicKey,
}

export interface TokenBag {
  mint: PublicKey;
  amount: number;
  amountUi: number;
  amountUiString: string;
  decimals: number;
  name: string;
  symbol: string;
  tokenAccount: PublicKey;
}

export enum WalletType {
  STACHE = 'STACHE',
  CONNECTED = 'CONNECTED',
  LINKED = 'LINKED',
  VAULT = 'VAULT',
}

export interface IWallet {
  label: string;
  walletType: WalletType;
  address: PublicKey;
  collectibles: Collectible[];
  tokens: TokenBag[];
}

//// // these are the states/interfaces used when dealing with home screen functions


// "actionable" wallets: stache, connected, vault
export interface StacheWallet extends IWallet {
}

export interface ConnectedWallet extends IWallet {
}

export interface VaultWallet extends IWallet {
}

// view-only wallets
export interface LinkedWallet extends IWallet {
}

export interface AppState {
  // the "view" objects the user is currently working/interacting with
  tokenBag?: TokenBag;
  collectible?: Collectible;
  wallet?: IWallet;
}

import {consoleLog} from "../../_helpers/debug";
import {DOMAINPDA, findKeychainKeyPda, findKeychainPda, findKeychainStatePda} from "../../programs/keychain-utils";
import {LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction} from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import {AnchorProvider, Program, web3} from "@project-serum/anchor";
import {getKeychainProgram, getStacheProgram} from "../../programs/program-utils";
import {findStachePda} from "../../programs/stache-utils";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  getAssociatedTokenAddressSync,
  TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import {AccountResponse, SDomain, SKeychain, SStache, STokenAccount} from './solana-types'
import {RPC_URL} from "../config";

// note: this class is used by both the frontend and backend; at some point it should be consolidated into a
//       common repository/package

export class SolanaClient {
  private keychainProg: Program;
  private stacheProg: Program;
  private provider: AnchorProvider;

  // after login, these get set (like having a jwt/session)
  private initialized: boolean = false;   // indicates we've "connected" to our stache
  private stacheid: string;
  private stachePda: PublicKey;
  private keychainPda: PublicKey;

  constructor(provider: AnchorProvider) {
    this.provider = provider;
    this.keychainProg = getKeychainProgram(provider);
    this.stacheProg = getStacheProgram(provider);
    this.stacheid = ''
  }

  init(stacheid: string, stachePda: PublicKey, keychainPda: PublicKey) {
    this.stacheid = stacheid;
    this.stachePda = stachePda;
    this.keychainPda = keychainPda;
    this.initialized = true;
  }

  // pull the keychain account for a given wallet address (if it exists)
  async getKeychainByKeyWallet(walletAddress: PublicKey): Promise<AccountResponse<SKeychain>> {
    const [keychainKeyPda] = findKeychainKeyPda(walletAddress);

    // first: see if wallet is connected to a keychain
    let keychainKeyAcct = await this.keychainProg.account.keyChainKey.fetchNullable(keychainKeyPda);
    const resp: AccountResponse<SKeychain> = {
      pda: keychainKeyPda,
    };
    if (keychainKeyAcct) {

      consoleLog(`found keychain key account for wallet: ${walletAddress.toBase58()}, checking keychain account: ${keychainKeyAcct.keychain}`);

      // then the keychain should exist
      resp['account'] = await this.fetchKeychainByPda(keychainKeyAcct.keychain as PublicKey);
    } else {
      // shouldn't happen
      consoleLog(`couldn't find keychain account for keychain key: ${keychainKeyPda.toBase58()}`);
      // todo: bugsnag
    }
    return resp;
  }

  async fetchDomainByPda(domainPda: PublicKey): Promise<AccountResponse<SDomain>> {
    const resp = {
      pda: domainPda,
    };
    const domainAcct = await this.keychainProg.account.currentDomain.fetchNullable(domainPda);
    if (domainAcct) {
      resp['account'] = {
        name: domainAcct.name,
      };
    }
    return resp;
  }

  async getKeychainByPda(keychainPda: PublicKey): Promise<AccountResponse<SKeychain>> {
    const resp = {
      pda: keychainPda,
    };
    resp['account'] = await this.fetchKeychainByPda(keychainPda);
    return resp;
  }

  async fetchKeychainByPda(keychainPda: PublicKey): Promise<SKeychain> {
    const keychainAcct = await this.keychainProg.account.currentKeyChain.fetchNullable(keychainPda);
    let keychain = null;
    if (keychainAcct) {
      keychain = {
        name: keychainAcct.name,
        domainPda: keychainAcct.domain,
        numKeys: keychainAcct.numKeys,
        bump: keychainAcct.bump,
        version: keychainAcct.version,
        keys: [],
      };
      // @ts-ignore
      for (let key of keychainAcct.keys) {
        keychain.keys.push({key: key.key, verified: key.verified});
      }
    }
    return keychain;
  }

  // pull a stache account, given the stacheid (keychain name)
  async getStacheById(stacheid: string, domainPda: PublicKey): Promise<AccountResponse<SStache>> {
    const [stachePda] = findStachePda(stacheid, domainPda, this.stacheProg.programId);
    return await this.getStacheByPda(stachePda);
  }

  async getStacheByPda(stachePda: PublicKey): Promise<AccountResponse<SStache>> {
    const stache = await this.stacheProg.account.currentStache.fetchNullable(stachePda);
    const resp = {
      pda: stachePda,
    };
    if (stache) {
      resp['account'] = {
        stacheid: stache.stacheid,
        keychainPda: stache.keychain,
        domainPda: stache.domain,
        keychain: null,
        version: stache.version,
        bump: stache.bump,
        // todo: vaults
      };
    }
    return resp;
  }

  // create a keychain + stache
  async createStache(stacheid: string, walletAddress: PublicKey, sendTransaction, confirm = false): Promise<AccountResponse<SStache>> {
    const [keychainPda] = findKeychainPda(stacheid);
    const [keychainStatePda] = findKeychainStatePda(keychainPda);
    const [keychainKeyPda] = findKeychainKeyPda(walletAddress);
    const [stachePda, _] = findStachePda(stacheid, DOMAINPDA, this.stacheProg.programId);

    const tx = new Transaction();
    // add the create keychain ix
    tx.add(await this.keychainProg.methods.createKeychain(stacheid).accounts({
      keychain: keychainPda,
      keychainState: keychainStatePda,
      key: keychainKeyPda,
      domain: DOMAINPDA,
      authority: this.provider.wallet.publicKey,
      wallet: walletAddress,
      systemProgram: SystemProgram.programId,
    }).instruction());

    // add the create stache ix
    tx.add(await this.stacheProg.methods.createStache().accounts({
      stache: stachePda,
      keychain: keychainPda,
      keychainProgram: this.keychainProg.programId,
      systemProgram: SystemProgram.programId,
    }).instruction());

    let txid = await sendTransaction(tx, this.provider.connection);
    console.log(
        `created stache for ${stacheid}, keychain pda: ${keychainPda}, stache pda: ${stachePda},  in txid: ${txid}`
    );
    if (confirm) {
      await this.provider.connection.confirmTransaction(txid);
    }

    return await this.getStacheByPda(stachePda);
  }

  async airdrop(publicKey: PublicKey, amountInSol: number) {
    const txid = await this.provider.connection.requestAirdrop(publicKey, amountInSol * LAMPORTS_PER_SOL);
    await this.provider.connection.confirmTransaction(txid);
  }

  checkInit() {
    if (!this.initialized) {
      throw new Error('StacheClient not initialized');
    }
  }

  // todo: not sure why the connection we've got in config stopped working for confirmations
  async confirmTransaction(txid: string, confirm = true) {
    if (confirm) {
      consoleLog(`confirming txid: ${txid}`);
      const connection = new web3.Connection(RPC_URL, {
        commitment: 'confirmed',
        confirmTransactionInitialTimeout: 1000 * 5,
      });
      const latestBlockHash = await connection.getLatestBlockhash();
      await connection.confirmTransaction({
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature: txid,
      });
    }
  }

  // send a token(s) to stache's ata
  async stash(fromTokenAccount: PublicKey, mint: PublicKey, amountDecimal: number, numDecimals: number, sendTransaction, confirm = false): Promise<string> {
    this.checkInit();

    const stacheMintAta = getAssociatedTokenAddressSync(mint, this.stachePda, true);

    // first, check if the account exists or not
    const ataInfo = await this.provider.connection.getAccountInfo(stacheMintAta);
    const tx = new Transaction();
    if (!ataInfo) {
      // then we need to create the stache ata
      tx.add(createAssociatedTokenAccountInstruction(this.provider.wallet.publicKey, stacheMintAta, this.stachePda, mint));
    }

    const stashAmount = new anchor.BN(amountDecimal).mul(new anchor.BN(10 ** numDecimals));
    consoleLog(`stashing ${amountDecimal} tokens, BN: ${stashAmount.toString()} from ${fromTokenAccount.toString()} to ${stacheMintAta.toString()}`);

    // now the stash instruction
    tx.add(await this.stacheProg.methods.stash(stashAmount).accounts({
      stache: this.stachePda,
      keychain: this.keychainPda,
      stacheAta: stacheMintAta,
      mint: mint,
      owner: this.provider.wallet.publicKey,
      fromToken: fromTokenAccount,
      systemProgram: SystemProgram.programId,
      tokenProgram: TOKEN_PROGRAM_ID,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
    }).instruction());

    const txid = await sendTransaction(tx, this.provider.connection);
    await this.confirmTransaction(txid, confirm);
    consoleLog(`stashed ${amountDecimal}: mint: ${mint.toString()} to stache ${this.stachePda.toString()}, ata: ${stacheMintAta.toString()}, from user ata ${fromTokenAccount.toString()}, in txid: ${txid}`);
    return txid;
  }


  // withdraw token(s) from a stache ata to user's ata
  async unstash(fromStacheAta: PublicKey, mint: PublicKey, amountDecimal: number, numDecimals: number, sendTransaction, confirm = false): Promise<string> {
    this.checkInit();

    // for now we unstash to a user's wallet, so don't allow offcurve (pda)
    const userAta = getAssociatedTokenAddressSync(mint, this.provider.wallet.publicKey, false);

    const ataInfo = await this.provider.connection.getAccountInfo(userAta);
    const tx = new Transaction();
    if (!ataInfo) {
      // then we need to create the stache ata for the user
      tx.add(createAssociatedTokenAccountInstruction(this.provider.wallet.publicKey, userAta, this.provider.wallet.publicKey, mint));
    }

    const unstashAmount = new anchor.BN(amountDecimal).mul(new anchor.BN(10 ** numDecimals));
    tx.add(await this.stacheProg.methods.unstash(unstashAmount).accounts({
      stache: this.stachePda,
      keychain: this.keychainPda,
      stacheAta: fromStacheAta,
      mint: mint,
      owner: this.provider.wallet.publicKey,
      toToken: userAta,
      tokenProgram: TOKEN_PROGRAM_ID,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
    }).instruction());

    const txid = await sendTransaction(tx, this.provider.connection);
    await this.confirmTransaction(txid, confirm);
    consoleLog(`unstashed ${amountDecimal}: mint: ${mint.toString()} from stache ${this.stachePda.toString()}, ata: ${fromStacheAta.toString()}, to user ata: ${userAta.toString()} in txid: ${txid}`);
    return txid;
  }

  async getTokenAccounts(walletAddress: PublicKey): Promise<STokenAccount[]> {
    const resp = await this.provider.connection.getParsedTokenAccountsByOwner(walletAddress, {programId: TOKEN_PROGRAM_ID});
    return resp.value.map((a) => {
      return {
        address: a.pubkey,
        mint: a.account.data.parsed.info.mint,
        decimals: a.account.data.parsed.info.tokenAmount.decimals,
        amount: a.account.data.parsed.info.tokenAmount.amount,
        amountUi: a.account.data.parsed.info.tokenAmount.uiAmount,
        amountUiString: a.account.data.parsed.info.tokenAmount.uiAmountString,
        lamports: a.account.lamports,
      };
    });
  }

  async getSolTokenAccount(walletAddress: PublicKey): Promise<STokenAccount> {
    const solAmount = await this.provider.connection.getBalance(walletAddress);
    return {
      address: walletAddress,
      mint: walletAddress,
      decimals: 9,
      amount: solAmount,
      amountUi: solAmount / LAMPORTS_PER_SOL,
      amountUiString: (solAmount / LAMPORTS_PER_SOL).toString(),
      lamports: solAmount,
    }
  }


  clone() {
    return new SolanaClient(this.provider);
  }
}

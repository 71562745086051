import { BackIcon } from "assets/icons/BackIcon";
import { MediumText, ThinText } from "components/ui/text";
import { BRADIUS, COLORS, PADDING, SPACING } from "constants/theme";
import React, { useCallback } from "react";
import { View, StyleSheet, Text, TouchableOpacity, } from "react-native";

interface ISettingsCardProps {
    text: string
    icon?: React.ReactNode
    onPress?: () => void
}

export const SettingsCard: React.FC<ISettingsCardProps> = ({ text, icon, onPress }) => {
    return (
        <TouchableOpacity style={styles.wrapper} onPress={onPress}>
            <View style={styles.info}>
                <View style={styles.iconWrapper}>
                    {icon}
                </View>

                <MediumText style={{color: '#03000F'}}>{text}</MediumText>
            </View>

            <BackIcon style={{transform: 'rotate(180deg)'}} />
        </TouchableOpacity>
    )
}

interface IFAQCardProps {
    title: string
    text: string
    description: string
    onPress?: (title: string, text: string) => void
}

export const FAQCard: React.FC<IFAQCardProps> = ({ title, text, description, onPress }) => {
    const handleOnPress = useCallback(() => {
        onPress(title, text)
    }, [onPress, title, text])

    return (
        <TouchableOpacity 
            style={{
                ...styles.wrapper,
                paddingRight: 0,
                paddingLeft: 0,
                borderBottomWidth: 0.5,
                borderBottomColor: 'rgba(0, 0, 0, 0.3',
            }} 
            onPress={handleOnPress}
        >
            <View>
                <MediumText style={{color: '#03000F'}}>{title}</MediumText>
                <ThinText style={{color: '#2D3645', lineHeight: 28}}>{description}</ThinText>
            </View>

            <BackIcon style={{transform: 'rotate(180deg)'}} />
        </TouchableOpacity>
    )
} 

const styles = StyleSheet.create({
    wrapper: {
        padding: PADDING.XL,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    info: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: SPACING.LG
    },
    iconWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 32,
        height: 32,
        backgroundColor: '#EFEFEF',
        borderRadius: BRADIUS.ROUND
    }
})
import React from 'react';
import { ComponentProps } from 'react';
import { Svg, Path } from 'react-native-svg';

interface IHomeIconProps extends ComponentProps<'svg'> {
    color?: string 
}

export const HomeIcon: React.FC<IHomeIconProps> = ({ color, width, height }) => {
    return (
        <Svg 
            width={width || 20} 
            height={height || 20} 
            viewBox="0 0 18 19" 
            fill="none"
        >
            <Path 
                d="M11.25 17.5V13C11.25 12.801 11.171 12.6103 11.0303 12.4696C10.8897 12.329 10.6989 12.25 10.5 12.25H7.5C7.30109 12.25 7.11032 12.329 6.96967 12.4696C6.82902 12.6103 6.75 12.801 6.75 13V17.5C6.75 17.6989 6.67098 17.8896 6.53033 18.0303C6.38968 18.1709 6.19891 18.25 6 18.25H1.5C1.30109 18.25 1.11032 18.1709 0.96967 18.0303C0.829018 17.8896 0.75 17.6989 0.75 17.5V8.82808C0.751679 8.72429 0.77411 8.62188 0.815972 8.52688C0.857833 8.43189 0.918279 8.34623 0.99375 8.27496L8.49375 1.45933C8.63201 1.33284 8.81261 1.2627 9 1.2627C9.18739 1.2627 9.36799 1.33284 9.50625 1.45933L17.0062 8.27496C17.0817 8.34623 17.1422 8.43189 17.184 8.52688C17.2259 8.62188 17.2483 8.72429 17.25 8.82808V17.5C17.25 17.6989 17.171 17.8896 17.0303 18.0303C16.8897 18.1709 16.6989 18.25 16.5 18.25H12C11.8011 18.25 11.6103 18.1709 11.4697 18.0303C11.329 17.8896 11.25 17.6989 11.25 17.5Z"
                fill={color || '#FFF'} 
                stroke={color || "#575E6A"} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}
import {CommonActions, useNavigation} from "@react-navigation/native";
import {Avatar} from "components/Avatar";
import {CustomInput} from "components/CustomInput";
import {BlackButton} from "components/ui/button";
import {ScreenContainer} from "components/ui/container";
import {MediumText, NormalText, SubHeaderText} from "components/ui/text";
import {ERoute} from "constants/routes";
import {BRADIUS, COLORS, PADDING, SPACING} from "constants/theme";
import React, {useCallback, useState} from "react";
import {StyleSheet, View} from "react-native";
import {useRecoilValue} from "recoil";
import {consoleLog} from "../../../_helpers/debug";
import {homepageAtom} from "../../../_state/screen-props";
import {AssetCard} from "../../../components/stash-transfers/common/AssetCard";
import {CollectibleCard} from "../../../components/stash-transfers/common/CollectibleCard";

interface ISendingProps {
}

export const Sending: React.FC<ISendingProps> = () => {
  const homepageProps = useRecoilValue(homepageAtom)
  const [noteInput, setNoteInput] = useState({note: ''})
  const navigation = useNavigation()

  const handleNavigateToScreen = useCallback(() => {
    navigation.dispatch({...CommonActions.navigate(ERoute.CONFIRM_PAYMENT)})
    consoleLog(noteInput)
  }, [navigation])

  const handleChangeNote = useCallback((text: string) => {
    setNoteInput(noteInput => {
      return {
        ...noteInput,
        note: text
      }
    })
  }, [setNoteInput])

  return (
      <ScreenContainer style={{justifyContent: 'space-between'}}>
        <View style={{gap: 50}}>
          <View style={{width: '100%', alignItems: 'center', gap: SPACING.LG, marginTop: 16}}>
            <Avatar style={styles.avatar} iconUrl=""/>

            <SubHeaderText>
              {homepageProps.asset ? ' 0xs233333332dssss32s111dxdx...' : '@userstacheid/label'}
            </SubHeaderText>

            {homepageProps.asset
                ? <NormalText>@userstacheid/label</NormalText>
                : <View style={styles.textWrapper}>
                  <MediumText style={{color: '#6D6D6D'}}>0xs233333332dssss32s...111dxdx</MediumText>
                </View>
            }
          </View>

          <View style={{gap: SPACING.LG}}>
            <MediumText>From Stache wallet</MediumText>

            {homepageProps.asset
                ? <AssetCard abbrev='SOL' action='sending' value={20} balance='100.000'/>
                : <CollectibleCard action='sending' name='Pixelated #8790' price='~$70' value='7.7 sol'/>
            }
          </View>

          <View>
            <MediumText style={{color: '#6D6D6D'}}>Add a note</MediumText>

            <CustomInput value={noteInput.note} placeholder='What is this for?' onChangeText={handleChangeNote}/>
          </View>
        </View>

        <View style={{gap: SPACING.XXL}}>
          <View style={styles.info}>

            {/*
                    <View style={styles.infoRow}>
                        <View style={{flexDirection: 'row', gap: SPACING.MD, alignItems: 'center'}}>
                            <ThinText>On-Chain Fee</ThinText>
                            <CircleContainer style={styles.circle}/>
                        </View>
                        <ThinText>0.6%</ThinText>
                    </View>
*/}


            {/*
                    <View style={styles.infoRow}>
                        <View style={{flexDirection: 'row', gap: SPACING.MD, alignItems: 'center'}}>
                            <ThinText>Estimated Time of Arrival</ThinText>
                            <CircleContainer style={styles.circle}/>
                        </View>
                        <ThinText>10-30mins</ThinText>
                    </View>
*/}
          </View>

          <View style={{width: '100%', alignItems: 'center'}}>
            <BlackButton label='Continue' onPress={handleNavigateToScreen} style={{marginBottom: 32}} icon/>
          </View>
        </View>
      </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  avatar: {
    width: 64,
    height: 64,
  },
  textWrapper: {
    padding: PADDING.MD,
    borderWidth: 1,
    borderColor: COLORS.BLACK,
    borderRadius: BRADIUS.MD
  },
  info: {
    padding: PADDING.XL,
    gap: SPACING.LG,
    backgroundColor: 'rgba(225, 225, 225, 0.24)'
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  circle: {
    width: 16,
    height: 16
  }
})

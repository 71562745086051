import { COLORS } from 'constants/theme';
import React from 'react';
import { ComponentProps, CSSProperties } from 'react';
import { Svg, Path } from 'react-native-svg';

interface ICustomizeIconProps extends ComponentProps<'svg'> {
    color?: string 
    style?: Partial<Record<keyof CSSProperties, any>>
}

export const CustomizeIcon: React.FC<ICustomizeIconProps> = ({ color, width, height, style={} }) => {
    return (
        <Svg 
            width={width || 32} 
            height={height || 32} 
            viewBox="0 0 32 32" 
            fill="none"
        >
            <Path 
                d="M16 15V27" 
                stroke={color || COLORS.BLACK} 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M16 5V11" 
                stroke={color || COLORS.BLACK} 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M25 25V27" 
                stroke={color || COLORS.BLACK} 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M25 5V21" 
                stroke={color || COLORS.BLACK} 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M28 21H22" 
                stroke={color || COLORS.BLACK} 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M7 21V27" 
                stroke={color || COLORS.BLACK} 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M7 5V17" 
                stroke={color || COLORS.BLACK} 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M4 17H10" 
                stroke={color || COLORS.BLACK} 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M19 11H13" 
                stroke={color || COLORS.BLACK} 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}

import {CommonActions, useNavigation} from "@react-navigation/native";
import {Search} from "components/Search";
import {FAQCard} from "components/SettingsCard";
import {ScreenContainer} from "components/ui/container";
import {HeaderText, NormalText} from "components/ui/text";
import {ERoute} from "constants/routes";
import {SPACING} from "constants/theme";
import {uniqueId} from "lodash";
import React, {useCallback, useState} from "react";
import {View} from "react-native";
import {useRecoilState, useRecoilValue} from "recoil";
import {faqAtom} from "../../../_state/screen-props";
import data from '../../../programs/idl/faq.json';

interface IFAQProps {
}

export const FAQ: React.FC<IFAQProps> = () => {
  const [, setFaqInfo] = useRecoilState(faqAtom)
  const [searchTerm, setSearchTerm] = useState('')
  const navigation = useNavigation()

  const handleNavigateToScreen = useCallback((title: string, text: string) => {
    setFaqInfo(faqInfo => {
      return {
        ...faqInfo,
        title: title,
        text: text
      }
    })

    navigation.dispatch({...CommonActions.navigate(ERoute.FAQ_INFO)})
  }, [navigation, setFaqInfo])

  return (
      <ScreenContainer>
        <Search
            placeholder='Search not functional yet...'
            value={searchTerm}
            onChangeText={setSearchTerm}
        />

        <View style={{marginTop: 48}}>
          {data.map(item => {
            const description = item.text.split('\n').find(x => x.length > 20).slice(0, 50) + '...'

            return (
                <FAQCard
                    key={uniqueId()}
                    title={item.title}
                    description={description}
                    text={item.text}
                    onPress={handleNavigateToScreen}
                />
            )
          })}
        </View>
      </ScreenContainer>
  )
}

interface IFAQInfoProps {
}

export const FAQInfo: React.FC<IFAQInfoProps> = () => {
  const faqInfo = useRecoilValue(faqAtom)

  const txt = faqInfo.text.split('\n')

  return (
      <ScreenContainer style={{paddingBottom: 32}}>
        <HeaderText
            style={{fontSize: 32, lineHeight: 48, marginBottom: SPACING.XL}}
        >
          {faqInfo.title}
        </HeaderText>

        {txt.map(x => {
          if (x[0] === '*') {
            const subTitle = x.replaceAll('*', '')
            return <HeaderText style={{marginTop: SPACING.XL}}>{subTitle}</HeaderText>
          } else {
            return <NormalText style={{color: '#2D3645', marginTop: SPACING.MD}}>{x}</NormalText>
          }
        })}
      </ScreenContainer>
  )
}

import {Wallet} from "@project-serum/anchor";
import {AnchorWallet, useWallet} from "@solana/wallet-adapter-react";
import {PublicKey} from "@solana/web3.js";
import {apiClient} from "constants/apis/apiclient";
import {createProvider} from "programs/keychain-utils";
import {useCallback} from "react";
import {useRecoilState, useResetRecoilState, useSetRecoilState} from "recoil";
import {consoleLog} from "../_helpers/debug";
import {useFetchWrapper} from "../_helpers/fetch-wrapper";
import {providerAtom} from "../_state/provider";
import {SolanaClient} from "../constants/apis/solanaclient";
import {solanaAtom} from "../_state/solana";
import {ConnectedWalletInfo, IStache} from "../_state/state-types";
import {stacheAtom} from "../_state/stache-state";
import {walletAtom} from "../_state/wallet-state";
import heliusclient from "constants/apis/heliusclient";

function useWalletActions() {
  const [wallet, setWallet] = useRecoilState<ConnectedWalletInfo>(walletAtom);
  const [, setStache] = useRecoilState<IStache>(stacheAtom);
  const setSolana = useSetRecoilState(solanaAtom);
  const resetSoalna = useResetRecoilState(solanaAtom);
  const setProvider = useSetRecoilState(providerAtom);
  const {disconnect} = useWallet();


  // const getWallet = useCallback(() => {
  //   solanaClient.getWallet()
  //       .then(setWallet)
  // }, [setWallet])

  const setLinkedWalletData = async (walletAddress: PublicKey) => {
    heliusclient.getNFTsForOwner(walletAddress)
        .then(console.log)
        .catch((err) => {
          console.error(err)
        })
  }

  const setBalancesData = async (walletAddress: PublicKey) => {
    heliusclient.getBalances(walletAddress)
        .then(console.log)
        .catch((err) => {
          console.error(err)
        })
  }

  const setWalletData = async (anchorWallet: AnchorWallet) => {
    const walletPublicKey: PublicKey = anchorWallet.publicKey;

    // create and set the provider
    const provider = createProvider(anchorWallet as Wallet);
    setProvider(provider);

    const solanaClient = new SolanaClient(provider);

    // create and set the keychain/stache program
    setSolana({solanaClient});

    // set this last, as other atoms that depend on the wallet also depend on the solana client
    const walletState = {address: walletPublicKey, checkedForStache: false, walletLinked: false};
    setWallet(walletState);
  };

  async function connectWallet(
      anchorWallet: AnchorWallet,
      signMessage: ((message: Uint8Array) => Promise<Uint8Array>
          ) | undefined = null) {

    try {
      const walletPublicKey: PublicKey = anchorWallet.publicKey;
      consoleLog('connecting wallet: ', walletPublicKey.toBase58())

      // this will set the wallet, provider, and programs atomically
      await setWalletData(anchorWallet);

    } catch (error) {
      consoleLog('Error connecting wallet: ', error);
      return useFetchWrapper().handleError(error as string);
    }
  }

  const setAvatar = useCallback(async (imgUrl: string) => {
    await apiClient.setAvatar(imgUrl).then(() => {
      setStache(stache => {
        return {
          ...stache,
          avatarUrl: imgUrl
        }
      })
    })
  }, [])

  async function disconnectWallet() {
    await disconnect();
    resetSoalna();
    setProvider(null);
    setWallet(null);
  }

  return {
    setWalletData,
    setAvatar,
    connectWallet,
    disconnectWallet,
    setLinkedWalletData,
    setBalancesData
  }
}

export {useWalletActions}

import { CustomInput } from "components/CustomInput";
import { BlackButton } from "components/ui/button";
import { ScreenContainer } from "components/ui/container";
import { MediumText } from "components/ui/text";
import { WalletInfo } from "components/WalletInfo";
import React, { useCallback, useState } from "react";
import { View } from "react-native";

interface INewUserProps {}

export const NewUser: React.FC<INewUserProps> = () => {
    const [ labelValue, setLabelValue ] = useState({label: ''})

    const handleLabelValue = useCallback((text: string) => {
        setLabelValue(labelValue => {
            return {
                ...labelValue,
                label: text
            }
        })
    }, [setLabelValue])

    return (
        <ScreenContainer style={{justifyContent: 'space-between'}}>
            <View style={{gap: 50}}>
                <WalletInfo />

                <View>
                    <CustomInput 
                        value={labelValue.label} 
                        onChangeText={handleLabelValue}
                        label='Enter a Label'
                        placeholder="Enter a label e.g joshua123.th"
                    />
                    <MediumText style={{color: '#667085', marginTop: 8}}>
                        Hint: This helps you when you want to send money another time  to this wallet address
                    </MediumText>
                </View>
            </View>

            <View style={{width: '100%', alignItems: 'center'}}>
                <BlackButton label='Add user' onPress={() => console.log('Add new user')} />
            </View>
        </ScreenContainer>
    )
}
import { CommonActions, useNavigation } from '@react-navigation/native';
import { ThinText } from 'components/ui/text';
import { ERoute } from 'constants/routes';
import { uniqueId } from 'lodash';
import React, { useCallback, useState } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

export interface ITab {
    name: string
    route: ERoute | null
}

interface ITabsProps {
    tabs: ITab[]
    setRoute?: (route: ERoute) => void
    style?: any
}

export const Tabs: React.FC<ITabsProps> = ({ tabs, setRoute, style={} }) => {
    const [ isActiveTab, setIsActiveTab ] = useState<ERoute>(ERoute.WALLET_ASSETS)

    const navigation = useNavigation()

    const handleNavigateToTab = useCallback((route: ERoute) => {
        return () => {
            navigation.dispatch({...CommonActions.navigate(route)})
            setIsActiveTab(route)
            
            if (setRoute) {
                setRoute(route)
            }
        }
    }, [navigation])

    return (
        <View style={{...styles.wrapper, ...style}}>
            {tabs.map(tab => {
                return (
                    <TouchableOpacity
                        key={uniqueId(tab.name)}
                        onPress={handleNavigateToTab(tab.route)}
                        style={[styles.tab, isActiveTab === tab.route  && styles.activeTab]}
                    >
                        <ThinText 
                            style={isActiveTab === tab.route ? {color: '#030E20'} : {color: '#737373'}}
                        >
                            {tab.name}
                        </ThinText>
                    </TouchableOpacity>
                )
            })}
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: '#C0C0C0',
    },
    tab: {
        padding: 10
    },
    activeTab: {
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
        marginBottom: -1
    }
})
import {CommonActions, useNavigation} from "@react-navigation/native";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {BackIcon} from "assets/icons/BackIcon";
import {Tabs} from "components/Tabs";
import {BlackButton, WhiteButton} from "components/ui/button";
import {ScreenContainer} from "components/ui/container";
import {MediumText, SubHeaderText} from "components/ui/text";
import {ERoute} from "constants/routes";
import {PADDING, SPACING} from "constants/theme";
import React, {useCallback} from "react";
import {StyleSheet, TouchableOpacity, View} from "react-native";
import {useRecoilValue} from "recoil";
import {homepageAtom} from "../../_state/screen-props";
import {AssetsHistory} from "./AssetsHistory";
import {Details} from "./Details";
import {appStateAtom} from "../../_state/app-state";

interface IAssetsDetailsProps {
}

// @ts-ignore
export const AssetsDetails: React.FC<IAssetsDetailsProps> = () => {
  const Tab = createNativeStackNavigator();
  const navigation = useNavigation();
  const homepageProps = useRecoilValue(homepageAtom);
  const appState = useRecoilValue(appStateAtom);

  const handleNavigateToScreen = useCallback((route: ERoute) => {
    return () => navigation.dispatch({...CommonActions.navigate(route)});
  }, [navigation]);

  return (
      <ScreenContainer style={{padding: 0}}>
        <TouchableOpacity onPress={handleNavigateToScreen(ERoute.HOMEPAGE)} style={styles.backIcon}>
          <BackIcon width={20} height={20}/>
        </TouchableOpacity>

        <View style={{flex: 1, backgroundColor: '#D9D9D93B'}}></View>

        <View style={styles.container}>
          <View style={styles.main}>
            <View style={styles.info}>
              <MediumText>
                {homepageProps.stache ? 'Stache' : 'Connected'} Wallet
              </MediumText>
              <SubHeaderText style={{fontWeight: '600'}}>
                {appState.tokenBag && appState.tokenBag.name}
                {appState.collectible && appState.collectible.name}
              </SubHeaderText>
            </View>

            <View style={{...styles.info, alignItems: 'flex-end'}}>
              <MediumText>Balance</MediumText>
              {appState.tokenBag && <SubHeaderText>{appState.tokenBag.amountUiString}</SubHeaderText>}
              {appState.collectible && <SubHeaderText>1</SubHeaderText>}
            </View>
          </View>

          <Tabs
              tabs={[
                {
                  name: 'Deatils',
                  route: ERoute.DETAILS
                },
                {
                  name: 'History',
                  route: ERoute.ASSETS_HISTORY
                }
              ]}
          />

          <Tab.Navigator initialRouteName={ERoute.DETAILS}>
            <Tab.Screen
                name={ERoute.DETAILS}
                component={Details}
                options={{headerShown: false}}
            />
            <Tab.Screen
                name={ERoute.ASSETS_HISTORY}
                component={AssetsHistory}
                options={{headerShown: false}}
            />
          </Tab.Navigator>

          <View style={styles.buttons}>
            <WhiteButton label='Send' onPress={handleNavigateToScreen(ERoute.SEARCH_ADDRESS)} backIcon/>

            <BlackButton
                label={homepageProps.stache
                    ? `Unstash`
                    : `Stash`
                }
                onPress={handleNavigateToScreen(ERoute.STASHING)}
                icon
            />
          </View>
        </View>
      </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  backIcon: {
    position: "absolute",
    left: 16,
    top: 24,
    zIndex: 9999
  },
  container: {
    flex: 1,
    padding: PADDING.XL,
    gap: SPACING.XXL
  },
  main: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  info: {
    gap: 4
  },
  buttons: {
    paddingTop: PADDING.LG,
    paddingBottom: PADDING.LG,
    gap: SPACING.LG,
    width: '100%',
    alignItems: 'center'
  }
})

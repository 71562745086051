import { CommonActions, useNavigation } from "@react-navigation/native";
import { WalletConnectedModal } from "components/Modals";
import { WhiteButton } from "components/ui/button";
import { ScreenContainer } from "components/ui/container";
import { NormalText } from "components/ui/text";
import { WalletInfo } from "components/WalletInfo";
import { ERoute } from "constants/routes";
import { BRADIUS, PADDING, SPACING } from "constants/theme";
import React, { useCallback, useState } from "react";
import { View, StyleSheet } from "react-native";

interface IDeleteWalletProps {}

export const DeleteWallet: React.FC<IDeleteWalletProps> = () => {
    const [openConnectedModal, setOpenConnectedModal] = useState(false)

    const navigation = useNavigation()

    const handleNavigateToRoute = useCallback(() => {
        navigation.dispatch({...CommonActions.navigate(ERoute.WALLET)})
        setOpenConnectedModal(false)
    }, [navigation, setOpenConnectedModal])

    const handleRemoveWallet = useCallback(() => {
        // functionality for removing wallet (when wallet array comes) 

        setOpenConnectedModal(true)
    }, [setOpenConnectedModal])

    return (
        <ScreenContainer style={{justifyContent: 'space-between'}}>
            <View style={{alignItems: 'center'}}>
                <WalletInfo />

                <View style={styles.status}>
                    <NormalText style={{lineHeight: 19, color: 'rgba(214, 0, 0, 0.9)'}}>Pending</NormalText>
                </View>
            </View>

            <View style={{width: '100%', alignItems: 'center'}}>
                <WhiteButton label='Delete wallet' onPress={handleRemoveWallet} style={{marginBottom: 32}} />
            </View>

            <WalletConnectedModal
                text='Wallet removed successfully'
                buttonLabel="Back to wallets"
                isOpen={openConnectedModal}
                handleNavigate={handleNavigateToRoute}
            />
        </ScreenContainer>
    )
}

const styles = StyleSheet.create({
    status: {
        borderRadius: BRADIUS.XXXL,
        paddingTop: PADDING.SM,
        paddingBottom: PADDING.SM,
        paddingLeft: PADDING.MD,
        paddingRight: PADDING.MD,
        backgroundColor: 'rgba(214, 0, 0, 0.3)',
        marginTop: SPACING.XL,
        maxWidth: 'fit-content'
    },
})
import { BannerText, ThinText } from 'components/ui/text';
import React from 'react';
import { View, StyleSheet, Image, Text, TouchableOpacity } from 'react-native';

interface INftCardProps {
    imageUrl: string
    name: string
    value: string
    price: string
    onPress?: () => void
    style?: any
}

export const NftCard: React.FC<INftCardProps> = ({ imageUrl, name, value, price, onPress, style={} }) => {
    return (
        <TouchableOpacity style={{...styles.wrapper, ...style}} onPress={onPress}>
            <Image source={{uri: imageUrl}} style={styles.image} />

            <View style={styles.content}>
                <View style={styles.contentWrapper}>
                    <BannerText>{name}</BannerText>
                    <BannerText style={{minWidth: 40, textAlign: 'right'}}>{value}</BannerText>
                </View>
                
                <View style={styles.contentWrapper}>
                    <ThinText>{name}</ThinText>
                    <BannerText style={{minWidth: 40, textAlign: 'right'}}>{price}</BannerText>
                </View>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        padding: 8,
        borderWidth: 1,
        borderColor: '#000',
        maxWidth: 160,
        height: 'fit-content'
    },
    image: {
        width: '100%',
        height: 105,
        backgroundColor: '#D9D9D9'
    },
    content: {
        paddingTop: 4,
        gap: 12
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 8
    }
})
import {useRecoilCallback, useRecoilState, useRecoilValue} from "recoil";
import {stacheWalletAtom} from "../_state/stachewallet-state";
import {consoleLog} from "../_helpers/debug";
import heliusclient from "../constants/apis/heliusclient";
import {ConnectedWallet, IStache, Solana, StacheWallet, TokenBag, WalletType} from "../_state/state-types";
import {stacheAtom} from "../_state/stache-state";
import {solanaAtom} from "../_state/solana";
import {STokenAccount} from "../constants/apis/solana-types";
import {useWallet} from "@solana/wallet-adapter-react";

function useStacheWalletActions() {
  const [stache, setStache] = useRecoilState<IStache>(stacheAtom);
  const solana: Solana = useRecoilValue(solanaAtom);
  const [stacheWallet, setStacheWallet] = useRecoilState(stacheWalletAtom);
  const {sendTransaction} = useWallet();


  // no idea if this is the right way to do this
  const stacheWalletLoader = useRecoilCallback(({snapshot, set}) => async () => {
    const stacheWallet = await snapshot.getPromise(stacheWalletAtom);
    if (!stacheWallet) {
      consoleLog('stache wallet not found, loading...');
      const solanaClient = solana.solanaClient;
      const tokenAccounts = await solanaClient.getTokenAccounts(stache.pda);
      // consoleLog(`got token accounts for ${stache.pda.toString()}: ${JSON.stringify(tokenAccounts, null, 2)}`);
      set(stacheWalletAtom, {
        label: stache.pda.toString(),
        address: stache.pda,
        walletType: WalletType.STACHE,
        collectibles: [],
        tokens: []
      });
    } else {
      consoleLog('stache wallet found, not loading');
    }
  });

  // load the stache wallet info
  async function load(force: boolean = false) {
    // fetch all the token accounts
    // await stacheWalletLoader();
    if (!stacheWallet || force) {
      consoleLog('stache wallet not found, loading...');
      const solanaClient = solana.solanaClient;
      const tokenAccounts: STokenAccount[] = await solanaClient.getTokenAccounts(stache.pda);
      // consoleLog(`got token accounts for ${stache.pda.toString()}: ${JSON.stringify(tokenAccounts, null, 2)}`);
      const assets = await heliusclient.fetchAssetMetaData(tokenAccounts);

      const solAccount = await solanaClient.getSolTokenAccount(stache.pda);
      const solBag: TokenBag = {
        name: "Solana",
        symbol: "SOL",
        mint: stache.pda,
        tokenAccount: stache.pda,
        ...solAccount
      };

      // make SOL the first tokenbag
      assets.tokens.unshift(solBag);

      const label = stacheWallet?.label || stache.pda.toString();
      setStacheWallet({
        label,
        walletType: WalletType.STACHE,
        address: stache.pda,
        collectibles: assets.collectibles,
        tokens: assets.tokens
      });
    }
  }

  async function stashTokens(connectedWallet: ConnectedWallet, stacheWallet: StacheWallet, tokenBag: TokenBag, amountUi: number): Promise<string> {
    const solanaClient = solana.solanaClient;
    consoleLog(`stashing ${amountUi} tokens from tokenbag: ${JSON.stringify(tokenBag, null, 2)}`);
    const txid = await solanaClient.stash(tokenBag.tokenAccount, tokenBag.mint, amountUi, tokenBag.decimals, sendTransaction, true);
    // await solanaClient.confirmTransaction(txid);
    return txid;
  }

  async function unstashTokens(stacheWallet: StacheWallet, connectedWallet: ConnectedWallet, tokenBag: TokenBag, amountUi: number): Promise<string> {
    const solanaClient = solana.solanaClient;
    consoleLog(`unstashing ${amountUi} tokens from tokenbag: ${JSON.stringify(tokenBag, null, 2)}`);
    const txid = await solanaClient.unstash(tokenBag.tokenAccount, tokenBag.mint, amountUi, tokenBag.decimals, sendTransaction, true);

    consoleLog('confirmed transaction');
    // await solanaClient.confirmTransaction(txid);
    return txid
  }


  return {
    load,
    stashTokens,
    unstashTokens
  }
}

export {useStacheWalletActions}

import {CustomDropdown} from "components/CustomDropdown";
import {SubHeaderText} from "components/ui/text";
import {EOption, IDropdownOption} from "constants/dropdown";
import React, {CSSProperties} from "react";
import {StyleSheet, View} from "react-native";
import {EType} from "../../_state/state-types";

interface ITabsHeaderProps {
  title: string
  options: IDropdownOption[]
  value: EOption | EType
  onChange: (value: EOption | EType) => void
  style?: Partial<Record<keyof CSSProperties, any>>
  styleDropdown?: Partial<Record<keyof CSSProperties, any>>
}

export const TabsHeader: React.FC<ITabsHeaderProps> = ({
                                                         title,
                                                         options,
                                                         onChange,
                                                         value,
                                                         style = {},
                                                         styleDropdown = {}
                                                       }) => {
  return (
      <View style={{...styles.header, ...style}}>
        <SubHeaderText>{title}</SubHeaderText>
        <CustomDropdown
            options={options}
            style={styleDropdown}
            onChange={onChange}
            value={value}
        />
      </View>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
})

import { NormalText } from 'components/ui/text';
import React, { CSSProperties, useCallback, useState } from 'react';
import { StyleSheet, TextInput, View } from "react-native";

interface ICustomInputProps {
    value: string 
    onChangeText: (text: string, name?: string) => void
    name?: string
    placeholder?: string
    label?: string
    defaultValue?: string
    onBlur?: () => void
    style?: Partial<Record<keyof CSSProperties, any>>
    styleLabel?: Partial<Record<keyof CSSProperties, any>>
}

export const CustomInput: React.FC<ICustomInputProps> = ({ 
    value, 
    onChangeText, 
    name, 
    placeholder, 
    label,
    defaultValue,
    onBlur,
    style={},
    styleLabel={}
}) => {
    const [ focusStyle, setFocusStyle ] = useState({
        borderWidth: styles.input.borderWidth,
        outline: 0
    })

    const handleOnChangeText = useCallback((text: string) => {
        onChangeText(text, name)
    }, [onChangeText, name])

    const handleOnFocus = useCallback(() => {
        setFocusStyle(focusStyle => ({...focusStyle, borderWidth: styles.input.borderWidth + 1}))
    }, [setFocusStyle])

    const handleOnBlur = useCallback(() => {
        setFocusStyle(focusStyle => ({...focusStyle, borderWidth: styles.input.borderWidth}))

        onBlur()
    }, [setFocusStyle, onBlur])

    return (
        <View>
            {label && <NormalText style={styleLabel}>{label}</NormalText>}

            <TextInput 
                placeholder={placeholder}
                placeholderTextColor='#667085'
                value={value}
                onChangeText={handleOnChangeText}
                selectTextOnFocus
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                style={{...styles.input, ...focusStyle, ...style}}
                defaultValue={defaultValue}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    input: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 14,
        paddingLeft: 14,
        borderWidth: 1,
        borderColor: '#000',
        borderRadius: 8,
        fontSize: 16,
        lineHeight: 19,
        width: '100%',
        marginTop: 4,
        outlineStyle: 'none'
    }
})
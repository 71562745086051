import {CommonActions, useNavigation} from '@react-navigation/native';
import { ScreenContainer } from 'components/ui/container';
import {Verification} from 'components/Verification';
import {ERoute} from 'constants/routes';
import React, {useCallback} from 'react';

interface ILoginProps {
  value: string
}

export const LinkWallet: React.FC<ILoginProps> = ({value}) => {
  const navigation = useNavigation()

  const handleNavigateToRoute = useCallback((route: ERoute) => {
    return () => {
      navigation.dispatch({...CommonActions.navigate(route)})
    }
  }, [navigation])

  return (
      <ScreenContainer style={{paddingTop: 0}}>
        <Verification
            title='Link Wallet'
            text='Verify your wallet by linking to an existing stache account that is yours'
            hint='Dont have a Stache Id?'
            link='Create one'
            handleNavigate={handleNavigateToRoute(ERoute.CREATE_NEW_STACHE)}
            value={value}
            buttonLabel='Fetch Keychain'
            handleButtonNavigate={() => null}
            creation={false}
        />
      </ScreenContainer>
  )
}

export const CreateNewStache: React.FC<ILoginProps> = ({value}) => {
  return (
      <ScreenContainer style={{paddingTop: 0}}>
        <Verification
            title='Create new Stache'
            hint='Hint: Usename already exist'
            link='Create'
            value={value}
            buttonLabel='Create Stache'
            handleButtonNavigate={() => null}
            creation={true}
        />
      </ScreenContainer>
  )
}

import {atom} from "recoil";
import {StacheWallet} from "./state-types";

export const stacheWalletAtom = atom<StacheWallet | null>({
  key: 'stache-wallet',
  default: null,
  // effects: [{
  // getPromise: async ({setSelf}) => {
  // }
  // }]
});

import { COLORS } from 'constants/theme';
import React from 'react';
import { ComponentProps } from 'react';
import { Svg, Path } from 'react-native-svg';

interface IPencilIconProps extends ComponentProps<'svg'> {
    size?: number
}

export const PencilIcon: React.FC<IPencilIconProps> = ({ size }) => {
    return (
        <Svg 
            width={size || 20} 
            height={size || 20} 
            viewBox="0 0 20 20" 
            fill="none"
        >
            <Path 
                d="M7.24219 17.3749H3.75C3.58424 17.3749 3.42527 17.3091 3.30806 17.1919C3.19085 17.0747 3.125 16.9157 3.125 16.7499V13.2577C3.12472 13.1766 3.14044 13.0962 3.17128 13.0211C3.20211 12.946 3.24745 12.8778 3.30469 12.8202L12.6797 3.44524C12.7378 3.38619 12.8072 3.33929 12.8836 3.30728C12.9601 3.27527 13.0421 3.25879 13.125 3.25879C13.2079 3.25879 13.2899 3.27527 13.3664 3.30728C13.4428 3.33929 13.5122 3.38619 13.5703 3.44524L17.0547 6.92962C17.1137 6.98777 17.1606 7.0571 17.1927 7.13355C17.2247 7.21 17.2411 7.29205 17.2411 7.37493C17.2411 7.45781 17.2247 7.53987 17.1927 7.61632C17.1606 7.69277 17.1137 7.76209 17.0547 7.82024L7.67969 17.1952C7.62216 17.2525 7.55391 17.2978 7.47884 17.3287C7.40376 17.3595 7.32335 17.3752 7.24219 17.3749Z" 
                stroke={COLORS.BLACK}
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M10.625 5.5L15 9.875" 
                stroke={COLORS.BLACK}
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}

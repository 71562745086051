import React, {FC, ReactElement, useCallback, useMemo} from 'react';
import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react';
import {
  BackpackWalletAdapter,
  CloverWalletAdapter,
  Coin98WalletAdapter,
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  TrustWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {WalletModalProvider} from '@solana/wallet-adapter-react-ui';

//Hooks
import useToasts from 'hooks/useToasts';

//Constants
import Constants from 'expo-constants';

//Types
import {NOTI_STATUS} from '../../_state/toasts';
import {RPC_URL} from "../../constants/config";

interface Props {
  children: React.ReactNode
}

export const ProviderComponentWrapper: FC<Props> = ({children}): ReactElement => {

  const {createToast} = useToasts();

  const onWalletError = useCallback(
      (error: any) => {
        createToast(
            error.message ? `${error.name}: ${error.message}` : `Error interacting with wallet: ${error.name}`,
            NOTI_STATUS.ERR
        )
      },
      [],
  );


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const wallets = useMemo(
      () => [
        new PhantomWalletAdapter(),
        new TorusWalletAdapter(),
        new LedgerWalletAdapter(),
        // @ts-ignore
        new SolflareWalletAdapter({network: Constants.expoConfig.extra.SOLANA_NETWORK}),
        new BackpackWalletAdapter(),
        new TrustWalletAdapter(),
        new CloverWalletAdapter(),
        new Coin98WalletAdapter(),
        // @ts-ignore
        new GlowWalletAdapter({network: Constants.expoConfig.extra.SOLANA_NETWORK}),
      ],
      []
  );

  return (
      <ConnectionProvider endpoint={RPC_URL}>
        <WalletProvider wallets={wallets} onError={onWalletError} autoConnect>
          <WalletModalProvider>{children}</WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
  );
}

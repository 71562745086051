import { ScreenContainer } from "components/ui/container";
import { NormalText } from "components/ui/text";
import React from "react";

interface IDetailsProps {}

export const Details: React.FC<IDetailsProps> = () => {
    return (
        <ScreenContainer style={{padding: 0}}>
            <NormalText>Description</NormalText>
        </ScreenContainer>
    )
}

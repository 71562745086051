import { COLORS } from 'constants/theme';
import React from 'react';
import { ComponentProps } from 'react';
import { Svg, Path } from 'react-native-svg';

interface IMagnifyingGlassIconProps extends ComponentProps<'svg'> {
    color?: string 
    style?: any
}

export const MagnifyingGlassIcon: React.FC<IMagnifyingGlassIconProps> = ({ color, width, height, style={} }) => {
    return (
        <Svg 
            width={width || 20} 
            height={height || 20} 
            viewBox="0 0 20 20" 
            fill="none"
            style={style}
        >
            <Path 
                d="M9.0625 15.625C12.6869 15.625 15.625 12.6869 15.625 9.0625C15.625 5.43813 12.6869 2.5 9.0625 2.5C5.43813 2.5 2.5 5.43813 2.5 9.0625C2.5 12.6869 5.43813 15.625 9.0625 15.625Z" 
                stroke={color || COLORS.BLACK} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M13.7031 13.7026L17.5 17.4995" 
                stroke={color || COLORS.BLACK} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}

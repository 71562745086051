import * as React from 'react';
import { Text, StyleProp, TextStyle, StyleSheet } from 'react-native';
import { COLORS, FONT_SIZE, LINE_HEIGHT } from 'constants/theme';

interface Props {
  children: string | React.ReactNode
  style?: StyleProp<TextStyle>
}

export const SubHeaderText: React.FunctionComponent<Props> = (props) : React.ReactElement => <Text style={[styles.subheader, props.style]}>{props.children}</Text>;
export const HeaderText: React.FunctionComponent<Props> = (props) : React.ReactElement => <Text style={[styles.header, props.style]}>{props.children}</Text>;
export const BigHeaderText: React.FunctionComponent<Props> = (props) : React.ReactElement => <Text style={[styles.bigHeader, props.style]}>{props.children}</Text>;
export const NormalText: React.FunctionComponent<Props> = (props) : React.ReactElement => <Text style={[styles.normal, props.style]}>{props.children}</Text>;
export const MediumText: React.FunctionComponent<Props> = (props) : React.ReactElement => <Text style={[styles.normal, props.style]}>{props.children}</Text>;
export const ThinText: React.FunctionComponent<Props> = (props) : React.ReactElement => <Text style={[styles.thin, props.style]}>{props.children}</Text>;
export const BannerText: React.FunctionComponent<Props> = (props) : React.ReactElement => <Text style={[styles.banner, props.style]}>{props.children}</Text>;
export const ErrorText: React.FunctionComponent<Props> = (props) : React.ReactElement => <Text style={[styles.normal, styles.error, props.style]}>{props.children}</Text>;

const styles = StyleSheet.create({
  bigHeader: {
    fontSize: FONT_SIZE.EXTRA_LARGE, // 24
    lineHeight: LINE_HEIGHT.EXTRA_LARGE // 36
  },
  header: {
    fontSize: FONT_SIZE.XXXL, // 20px
    lineHeight: LINE_HEIGHT.XXXL, //30px
    color: COLORS.MAIN_TEXT_DARK
  },
  subheader: {
    fontSize: FONT_SIZE.XXL, // 18px
    lineHeight: LINE_HEIGHT.XXL, // 27px
    color: COLORS.MAIN_TEXT_DARK
  },
  normal: {
    fontSize: FONT_SIZE.XL, // 16px
    lineHeight: LINE_HEIGHT.XL, // 24px
    color: COLORS.MAIN_TEXT_DARK
  },
  medium: {
    fontSize: FONT_SIZE.LG, // 14px
    lineHeight: LINE_HEIGHT.LG, // 21px
    color: COLORS.MAIN_TEXT_DARK
  },
  thin: {
    fontSize: FONT_SIZE.MD, // 12px
    lineHeight: LINE_HEIGHT.MD, // 18px
    color: COLORS.MAIN_TEXT_DARK
  },
  banner: {
    fontSize: FONT_SIZE.SM, // 10px
    lineHeight: LINE_HEIGHT.SM, // 15px
    color: COLORS.MAIN_TEXT_DARK
  },
  error: {
 
  }
})
import { COLORS } from 'constants/theme';
import React from 'react';
import { ComponentProps } from 'react';
import { Svg, Path } from 'react-native-svg';

interface IPlusIconProps extends ComponentProps<'svg'> {}

export const PlusIcon: React.FC<IPlusIconProps> = ({ width, height }) => {
    return (
        <Svg 
            width={width || 24} 
            height={height || 24} 
            viewBox="0 0 24 24" 
            fill="none"
        >
            <Path 
                d="M3.75 12H20.25" 
                stroke={COLORS.BLACK} 
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M12 3.75V20.25" 
                stroke={COLORS.BLACK} 
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}



import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {SettingsIcon} from 'assets/icons/SettingsIcon';
import React, {useCallback} from 'react';
import {ConnectWallet} from 'screens/ConnectWallet';
import {Home} from 'screens/Home';
import {AssetsDetails} from 'screens/AssetsDetails';
import {CreateNewStache, LinkWallet} from 'screens/Login';
import {ManageWallets} from 'screens/ManageWallets';
import {Notifications} from 'screens/Notifications';
import {Settings} from 'screens/Settings';
import {Wallet} from 'screens/Wallet';
import {Stashing} from 'screens/AssetsDetails/Stashing';
import {AddressBook} from 'screens/AddressBook';
import {PlusIcon} from 'assets/icons/PlusIcon';
import {EditUser, SelectUser} from 'screens/AddressBook/User';
import {AddUser} from 'screens/AddressBook/AddUser';
import {Sending} from 'screens/AssetsDetails/Sending';
import {ConfirmPayment} from 'screens/AssetsDetails/ConfirmPayment';
import {FAQ, FAQInfo} from 'screens/Settings/FAQ';
import {BackNavigationButton, RightNavigationButton} from 'components/ui/button';
import {useRecoilValue} from 'recoil';
import {homepageAtom} from './_state/screen-props';
import {CustomizeIcon} from 'assets/icons/CustomizeIcon';
import {CustomizeNotifications} from 'screens/Notifications/CustomizeNotifications';
import {Notify} from 'screens/Notifications/Notify';
import {SearchAddress} from 'screens/AssetsDetails/SearchAddress';
import {DeleteWallet} from 'screens/ManageWallets/DeleteWallet';
import {NewUser} from 'screens/AddressBook/NewUser';
import {COLORS} from 'constants/theme';
import {ERoute} from "./constants/routes";

interface IAppRoutesProps {
}

export const AppRoutes: React.FC<IAppRoutesProps> = () => {
  const Stack = createNativeStackNavigator()
  const homepageProps = useRecoilValue(homepageAtom)

  const handleNavigateToScreen = useCallback((navigation: any, route: ERoute) => {
    return () => navigation(route)
  }, [])

  return (
      <Stack.Navigator>
        {/* Connect wallet screens */}
        <Stack.Screen
            name={ERoute.CONNECT_WALLET}
            component={ConnectWallet}
            options={({route}) => ({
              headerShown: false
            })}
        />
        <Stack.Screen
            name={ERoute.LINK_WALLET}
            component={LinkWallet}
            options={({navigation}) => ({
              title: '',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.CONNECT_WALLET)}
                    />
                )
              }
            })}
        />
        <Stack.Screen
            name={ERoute.CREATE_NEW_STACHE}
            component={CreateNewStache}
            options={({navigation}) => ({
              title: '',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.CONNECT_WALLET)}
                    />
                )
              }
            })}
        />

        {/* Homepage screens */}
        <Stack.Screen
            name={ERoute.HOMEPAGE}
            component={Home}
            options={{
              headerShown: false
            }}
        />
        <Stack.Screen
            name={ERoute.ASSETS_DETAILS}
            component={AssetsDetails}
            options={{
              headerShown: false
            }}
        />
        <Stack.Screen
            name={ERoute.STASHING}
            component={Stashing}
            options={({navigation}) => ({
              title: homepageProps.stache
                  ? `Unstash ${homepageProps.asset ? 'Asset' : 'Collectible'}`
                  : `Stash ${homepageProps.asset ? 'Asset' : 'Collectible'}`,
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.ASSETS_DETAILS)}
                    />
                )
              },
              headerTitleAlign: 'center'
            })}
        />
        <Stack.Screen
            name={ERoute.SEARCH_ADDRESS}
            component={SearchAddress}
            options={({navigation}) => ({
              title: homepageProps.asset ? 'Send Asset' : 'Send Collectible',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.ASSETS_DETAILS)}
                    />
                )
              },
              headerTitleAlign: 'center'
            })}
        />
        <Stack.Screen
            name={ERoute.SENDING}
            component={Sending}
            options={({navigation}) => ({
              title: homepageProps.asset ? 'Send Asset' : 'Send Collectible',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.SEARCH_ADDRESS)}
                    />
                )
              },
              headerTitleAlign: 'center'
            })}
        />
        <Stack.Screen
            name={ERoute.CONFIRM_PAYMENT}
            component={ConfirmPayment}
            options={({navigation}) => ({
              title: 'Confirm Payment',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.SENDING)}
                    />
                )
              },
              headerTitleAlign: 'center'
            })}
        />

        {/* Address book screens */}
        <Stack.Screen
            name={ERoute.ADDRESS_BOOK}
            component={AddressBook}
            options={({navigation}) => ({
              title: 'My Address book',
              headerShadowVisible: false,
              headerLeft: () => null,
              headerRight: () => {
                return (
                    <RightNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.ADD_USER)}
                    >
                      <PlusIcon/>
                    </RightNavigationButton>
                )
              },
              headerTitleAlign: 'center'
            })}
        />
        <Stack.Screen
            name={ERoute.SELECT_USER}
            component={SelectUser}
            options={({navigation}) => ({
              title: 'My Address book',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.ADDRESS_BOOK)}
                    />
                )
              },
              headerTitleAlign: 'center'
            })}
        />
        <Stack.Screen
            name={ERoute.EDIT_USER}
            component={EditUser}
            options={({navigation}) => ({
              title: 'My Address book',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.SELECT_USER)}
                    />
                )
              },
              headerTitleAlign: 'center'
            })}
        />
        <Stack.Screen
            name={ERoute.ADD_USER}
            component={AddUser}
            options={({navigation}) => ({
              title: 'Add User',
              headerTitleAlign: 'center',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.ADDRESS_BOOK)}
                    />
                )
              },
            })}
        />
        <Stack.Screen
            name={ERoute.NEW_USER}
            component={NewUser}
            options={({navigation}) => ({
              title: 'Add User',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.ADD_USER)}
                    />
                )
              },
              headerTitleAlign: 'center'
            })}
        />

        {/* Notification screens */}
        <Stack.Screen
            name={ERoute.NOTIFICATIONS}
            component={Notifications}
            options={({navigation}) => ({
              title: 'Notification',
              headerShadowVisible: false,
              headerTitleAlign: 'center',
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.HOMEPAGE)}
                    />
                )
              },
              headerRight: () => {
                return (
                    <RightNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.CUSTOMIZE_NOTIFICATIONS)}
                    >
                      <CustomizeIcon/>
                    </RightNavigationButton>
                )
              }
            })}
        />
        <Stack.Screen
            name={ERoute.CUSTOMIZE_NOTIFICATIONS}
            component={CustomizeNotifications}
            options={({navigation}) => ({
              title: 'Customize Notifications',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.NOTIFICATIONS)}
                    />
                )
              },
              headerTitleAlign: 'center'
            })}
        />
        <Stack.Screen
            name={ERoute.NOTIFY}
            component={Notify}
            options={({navigation}) => ({
              title: 'Customize Notifications',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.CUSTOMIZE_NOTIFICATIONS)}
                    />
                )
              },
              headerTitleAlign: 'center'
            })}
        />

        {/* Settings screens */}
        <Stack.Screen
            name={ERoute.SETTINGS}
            component={Settings}
            options={{
              title: 'My Settings',
              headerShadowVisible: false,
              headerTitleAlign: 'center',
              headerLeft: () => null
            }}
        />
        <Stack.Screen
            name={ERoute.FAQ}
            component={FAQ}
            options={({navigation}) => ({
              title: 'Help & FAQ',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.SETTINGS)}
                    />
                )
              },
              headerTitleAlign: 'center'
            })}
        />
        <Stack.Screen
            name={ERoute.FAQ_INFO}
            component={FAQInfo}
            options={({navigation}) => ({
              title: 'Help & FAQ',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.FAQ)}
                    />
                )
              },
              headerTitleAlign: 'center'
            })}
        />

        {/* Wallet screens */}
        <Stack.Screen
            name={ERoute.WALLET}
            component={Wallet}
            options={({navigation}) => ({
              title: 'My Wallets',
              headerTitleAlign: 'center',
              headerShadowVisible: false,
              headerRight: () => {
                return (
                    <RightNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.MANAGE_WALLETS)}
                    >
                      <SettingsIcon color={COLORS.BLACK}/>
                    </RightNavigationButton>
                )
              },
              headerLeft: () => {
                return null
              }
            })}
        />
        <Stack.Screen
            name={ERoute.MANAGE_WALLETS}
            component={ManageWallets}
            options={({navigation}) => ({
              title: 'Manage Wallets',
              headerTitleAlign: 'center',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.WALLET)}
                    />
                )
              }
            })}
        />
        <Stack.Screen
            name={ERoute.DELETE_WALLET}
            component={DeleteWallet}
            options={({navigation}) => ({
              title: 'Manage Wallets',
              headerTitleAlign: 'center',
              headerShadowVisible: false,
              headerLeft: () => {
                return (
                    <BackNavigationButton
                        onPress={handleNavigateToScreen(navigation.navigate, ERoute.MANAGE_WALLETS)}
                    />
                )
              }
            })}
        />
      </Stack.Navigator>
  )
}

import { COLORS } from 'constants/theme';
import React from 'react';
import { ComponentProps } from 'react';
import { Svg, Path } from 'react-native-svg';

interface IQuestionIconProps extends ComponentProps<'svg'> {
    size?: number
}

export const QuestionIcon: React.FC<IQuestionIconProps> = ({ size }) => {
    return (
        <Svg 
            width={size || 16} 
            height={size || 16} 
            viewBox="0 0 16 16" 
            fill="none"
        >
            <Path 
                d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" 
                stroke={COLORS.BLACK} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <Path 
                d="M8 9V8.5C8.34612 8.5 8.68446 8.39736 8.97225 8.20507C9.26003 8.01278 9.48433 7.73947 9.61679 7.4197C9.74924 7.09993 9.7839 6.74806 9.71637 6.40859C9.64885 6.06913 9.48218 5.75731 9.23744 5.51256C8.99269 5.26782 8.68087 5.10115 8.34141 5.03363C8.00194 4.9661 7.65007 5.00076 7.3303 5.13321C7.01053 5.26566 6.73722 5.48997 6.54493 5.77775C6.35264 6.06554 6.25 6.40388 6.25 6.75" 
                stroke={COLORS.BLACK} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </Svg>
    )
}

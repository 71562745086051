export const SPACING = {
    SM: 4,
    MD: 8, 
    LG: 12,
    XL: 16,
    XXL: 24,
    XXXL: 32
}

export const PADDING = {
    SM: 4,
    MD: 8, 
    LG: 12,
    XL: 16,
    XXL: 24
}

export const COLORS = {
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    MAIN_TEXT_DARK: '#030E20',
    MAIN_TEXT_LIGHT: '#CFDAEC',
    SUB_TEXT_DARK: '#737373',
    SUB_TEXT_LIGHT: '#9A9A9A',
    NOT_ACTIVE: '#575E6A',
    DEFAULT_CIRCLE: '#D9D9D9'
}

export const BRADIUS = {
    NONE: 0,
    SM: 4,
    MD: 8, 
    LG: 12,
    XL: 16,
    XXL: 20,
    XXXL: 24,
    ROUND: 50
}

export const FONT_SIZE = {
    SM: 10,
    MD: 12,
    LG: 14,
    XL: 16,
    XXL: 18,
    XXXL: 20,
    EXTRA_LARGE: 24
}

export const LINE_HEIGHT = {
    SM: 15,
    MD: 18,
    LG: 21,
    XL: 24,
    XXL: 27,
    XXXL: 30,
    EXTRA_LARGE: 36
}
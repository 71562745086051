import {consoleLog} from "../../_helpers/debug";
import {API_URL} from "../config";
import axios, {AxiosResponse} from "axios";
import {PublicKey} from "@solana/web3.js";
import {IContact} from "../../_state/state-types";


export interface NonceResponse {
  nonceId: number;
  nonce: string;
}

// same as backend version
export interface ContactDto {
  id: number;
  label: string;
  stacheid: string | null;
  contactType: string;
  addresses: string[];
}

// same as backend version
export interface StacheInfo {
  id: number;
  stacheid: string;
  avatarUrl: string;
  stachePda: string;
  keychainPda: string;
  contacts: ContactDto[];
}

export interface LoginResponse {
  authToken: string;
  stache: StacheInfo;  // this is the "user" (StacheInfo object from backend)
}

class ApiClient {
  private apiUrl: string;
  // jwt token when logged in
  private authToken: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  async getAddressBook(): Promise<IContact[]> {
    return [
      {
        name: 'Contact1',
        stacheName: 'contact1',
        stacheAddress: null,
        walletAddress: null
      }
    ]
  }


  async setContact(contact: IContact): Promise<any> {
    consoleLog({contact})
  }

  async setAvatar(imgUrl: string): Promise<any> {
    consoleLog({imgUrl})
  }

  // retrieves the data object from an api response or throws an error
  getDataFromResponse(resp: AxiosResponse): any {
    const data = resp?.data;
    // for 500s
    if (resp.status !== 200) {
      if (data?.message) {
        throw new Error(data.message);
      } else {
        throw new Error('Unknown server error');
      }
    } else {
      if (data?.success) {
        return data.data;
      } else {
        if (data?.message) {
          throw new Error(data.message);
        } else {
          throw new Error('Unknown server error');
        }
      }
    }
  }

  async getNonce(address: PublicKey): Promise<NonceResponse> {
    const nonceResp = await axios.get(this.apiUrl + '/security/nonce?pubkey=' + address.toBase58());
    const data = this.getDataFromResponse(nonceResp);
    return {
      nonceId: data.id,
      nonce: data.nonce
    }
  }

  async login(nonceId: number, signature: number[], stachePda: PublicKey): Promise<LoginResponse> {
    const loginResp = await axios.post(this.apiUrl + '/auth/login', {
      nonceId,
      signature,
      stachePda: stachePda.toBase58()
    });
    const data = this.getDataFromResponse(loginResp);
    // save the auth token for future requests
    this.authToken = data.authToken;

    // this would now be an authenticated call
    // await this.getAllContacts();

    return data;
  }

  logout() {
    this.authToken = null;
  }

  getRequestConfig() {
    if (this.authToken) {
      return {
        headers: {Authorization: `Bearer ${this.authToken}`}
      }
    } else {
      return {}
    }
  }

  async getAllContacts() {
    const contactsResp = await axios.get(this.apiUrl + '/contacts/all', this.getRequestConfig());
    const contactsData = this.getDataFromResponse(contactsResp);
    consoleLog('contacts: ', contactsData);
    return contactsData;
  }

}

export const apiClient = new ApiClient(API_URL);

import { NormalText } from "components/ui/text";
import { COLORS } from "constants/theme";
import React from "react";
import { View } from "react-native";

interface IAssetsHistoryProps {}

export const AssetsHistory: React.FC<IAssetsHistoryProps> = () => {
    return (
        <View style={{backgroundColor: COLORS.WHITE, flex: 1}}>
            <NormalText>Assets History</NormalText>
        </View>
    )
}
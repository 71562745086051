// import anchor from "@project-serum/anchor";
import {Idl, web3} from '@project-serum/anchor';
import Constants from 'expo-constants';

import keychainIdl from 'programs/idl/keychain.json';
import stacheIdl from 'programs/idl/stache.json';
import {PublicKey} from "@solana/web3.js";

export const RPC_URL: string = Constants.expoConfig.extra.SOLANA_RPC;
export const API_URL: string = Constants.expoConfig.extra.BASE_API_URL;

export const MIXPANEL_API_KEY: string = Constants.expoConfig.extra.MIXPANEL_API_KEY;
export const ENABLE_ANALYTICS: boolean = Constants.expoConfig.extra.ENABLE_ANALYTICS == 'true';
export const ENABLE_LOGGING: boolean = Constants.expoConfig.extra.ENABLE_LOGGING == 'true'

export const isDev = () => {
  return Constants.expoConfig.extra.APP_ENV === 'dev';
}

export const isDevnet = () => {
  return RPC_URL.indexOf('devnet') > 0;
}

export const KEYCHAIN_DOMAIN = Constants.expoConfig.extra.KEYCHAIN_DOMAIN;
export const KEYCHAIN_TREASURY = Constants.expoConfig.extra.KEYCHAIN_TREASURY;
export const HELIUS_API_KEY = Constants.expoConfig.extra.HELIUS_API_KEY;

let heliusRpc = 'https://rpc.helius.xyz/?api-key=' + HELIUS_API_KEY;
if (isDevnet()) {
  heliusRpc = 'https://rpc-devnet.helius.xyz/?api-key=' + HELIUS_API_KEY;
}

export const HELIUS_RPC_URL = heliusRpc;

console.log(`logging: ${ENABLE_LOGGING}, analytics: ${ENABLE_ANALYTICS}, env: ${Constants.expoConfig.extra.APP_ENV}, rpc: ${RPC_URL}`);

if (ENABLE_LOGGING) {
  console.log(`logging: ${ENABLE_LOGGING}, analytics: ${ENABLE_ANALYTICS}, env: ${Constants.expoConfig.extra.APP_ENV}, rpc: ${RPC_URL}`);
}

const CONFIRM_TIMEOUT_MILLIS = 1000 * 30; // 30 sec
export const connection = new web3.Connection(RPC_URL, {
  commitment: 'confirmed',
  confirmTransactionInitialTimeout: CONFIRM_TIMEOUT_MILLIS,
});


// keychain stuff
export const KeychainIdl = keychainIdl as Idl;
export const KeychainProgramId = new web3.PublicKey(keychainIdl.metadata.address);

export const StacheIdl = stacheIdl as Idl;
export const StacheProgramId = new web3.PublicKey(stacheIdl.metadata.address);

export const ThreadProgId: PublicKey = new PublicKey('3XXuUFfweXBwFgFfYaejLvZE4cGZiHgKiGfMtdxNzYmv');
// export const ThreadProg = new Program(ThreadIdl, ThreadProgId, provider);



